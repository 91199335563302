import {
    ListItem,
    ListItemIcon,
    makeStyles,
    Typography
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        "& $icon": {
            color: theme.palette.primary.main
        }
    },
    icon: {
        minWidth: "inherit",
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(2)
    },
    link: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    }
}));

const SidebarItem = ({
    icon,
    text,
    url,
    search,
    target,
    useDefaultHeading,
    onClick
}) => {
    const classes = useStyles();
    return (
        <ListItem
            exact
            isActive={_ =>
                window.location.pathname === url &&
                window.location.search.replace("?", "") === (search || "")
            }
            component={NavLink}
            className={classes.link}
            activeClassName={classes.active}
            to={{
                pathname: url,
                state: { text: !useDefaultHeading && text },
                search
            }}
            target={target}
            onClick={onClick}
            button
        >
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <Typography variant="subtitle2">{text}</Typography>
        </ListItem>
    );
};

export default SidebarItem;
