import fileDownload from "js-file-download";
import useApi from "./useApi";

const useDownload = (setError, endpoint = "library") => {
    const { download, response } = useApi(endpoint);
    const downloadDocument = ({ id, name, extension }) => async e => {
        e.preventDefault();
        const document = await download(id);
        if (response.ok) {
            fileDownload(document, `${name}.${extension}`);
        } else {
            setError(`Unable to download document '${name}'`);
        }
    };
    return downloadDocument;
};

export default useDownload;
