import {
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import { Assignment, Delete, PictureAsPdf } from "@material-ui/icons";
import clsx from "clsx";
import { format } from "date-fns";
import React from "react";
import useUser from "../../useUser";
import { getName } from "../../util";

const useStyles = makeStyles(theme => ({
    item: {
        alignItems: "flex-start"
    },
    icon: {
        fontSize: 48,
        display: "flex",
        marginTop: theme.spacing()
    },
    primary: {
        color: theme.palette.text.primary
    },
    formName: {
        paddingRight: 48 // secondary action is already padded for one icon but this allows for two
    },
    actions: {
        [theme.breakpoints.down("xs")]: {
            right: 0
        }
    }
}));

const ByUser = ({ on, by, classes }) => (
    <>
        <span>{format(new Date(on), "PPPp")} by </span>
        <span className={classes.primary}>{getName(by)}</span>
    </>
);

const FormList = ({ forms, onEditForm, onDeleteForm, onExportPdf }) => {
    const classes = useStyles();
    const { isAdmin } = useUser();

    return forms.map(({ id, name, modified, modifiedBy }) => (
        <div key={`jobsheet-form-${id}`}>
            <ListItem
                onClick={() => onEditForm(id)}
                className={classes.item}
                button
            >
                <ListItemAvatar>
                    <Assignment className={classes.icon} color="action" />
                </ListItemAvatar>
                <ListItemText
                    className={clsx(isAdmin && classes.formName)}
                    primary={<Typography variant="body1">{name}</Typography>}
                    secondary={
                        <ByUser
                            on={modified}
                            by={modifiedBy}
                            classes={classes}
                        />
                    }
                />
                {isAdmin && (
                    <ListItemSecondaryAction className={classes.actions}>
                        <IconButton onClick={() => onExportPdf(id)}>
                            <PictureAsPdf />
                        </IconButton>
                        <IconButton onClick={() => onDeleteForm(id)}>
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    ));
};

export default FormList;
