import {
    CircularProgress,
    Grid,
    IconButton,
    makeStyles,
    TextField
} from "@material-ui/core";
import { NotListedLocation, Room } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import useDebounce from "../useDebounce";
import { makeReducer } from "../util";
import MapLink from "./MapLink";

const useStyles = makeStyles(theme => ({
    autocomplete: {
        paddingBottom: theme.spacing()
    },
    iconButton: {
        textAlign: "right",
        marginTop: theme.spacing(-0.5)
    }
}));

const reducerMap = {
    CHANGE_FIELD_MANUALLY: (state, action) => ({
        ...state,
        [action.name]: action.value,
        placeId: undefined,
        lat: undefined,
        lng: undefined
    }),
    SELECT_ADDRESS: (_, action) => ({
        ...action.address
    })
};

const xsFieldWidths = [12, 12, 12, 12, 12, 12, 9];
const fieldWidths = [3, 9, 7, 5, 4, 3, 4];
const fields = [
    "Number",
    "Street 1",
    "Street 2",
    "City",
    "County",
    "Postcode",
    "Country"
].map(label => ({ label, name: label.replace(" ", "").toLowerCase() }));

const AddressPicker = ({ onChange, address = {} }) => {
    const getAutocomplete = useCallback(
        () => new window.google.maps.places.AutocompleteService(),
        []
    );
    const getGeocoder = useCallback(
        () => new window.google.maps.Geocoder(),
        []
    );
    const getOK = useCallback(
        () => window.google.maps.places.PlacesServiceStatus.OK,
        []
    );
    const isOK = useCallback(value => value === getOK(), [getOK]);

    const classes = useStyles();
    const [value, setValue] = useState("");
    const [results, setResults] = useState([]);
    const debouncedValue = useDebounce(value, 500);
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useReducer(makeReducer(reducerMap), address);
    const [autocomplete, setAutocomplete] = useState("");
    const [locating, setLocating] = useState(false);

    const canLocate = state && (state.city || state.postcode || state.county);

    useEffect(() => {
        if (state !== address) {
            onChange(state);
        }
    }, [state, onChange, address]);

    useEffect(() => {
        if (debouncedValue) {
            setLoading(true);
            getAutocomplete().getPlacePredictions(
                { input: debouncedValue },
                async (suggestions, status) => {
                    if (isOK(status)) {
                        setResults(suggestions);
                    } else {
                        setResults([]);
                    }
                    setLoading(false);
                }
            );
        } else {
            setResults([]);
        }
    }, [debouncedValue, getAutocomplete, isOK]);

    const handleLocate = () => {
        setLocating(true);
        const address = fields
            .map(({ name }) => state[name])
            .filter(f => f)
            .join(", ");
        handleGeocode({ address });
    };

    const handleChange = name => e => {
        dispatch({
            name,
            value: e.target.value,
            type: "CHANGE_FIELD_MANUALLY"
        });
        setAutocomplete("");
        setValue("");
        setResults([]);
    };

    const handleGeocode = data => {
        getGeocoder().geocode(data, (results, status) => {
            if (isOK(status) && results && results.length) {
                const { place_id, address_components, geometry } = results[0];
                const result = address_components
                    .map(c => ({
                        type: c.types[0],
                        value: c.long_name
                    }))
                    .reduce(
                        (o, element) => ({
                            ...o,
                            [element.type]: element.value
                        }),
                        {}
                    );
                const { lat, lng } = geometry.location;
                dispatch({
                    type: "SELECT_ADDRESS",
                    address: {
                        number: result.street_number,
                        street1: result.route,
                        street2: result.locality,
                        city: result.postal_town,
                        county: result.administrative_area_level_2,
                        postcode: result.postal_code,
                        country: result.country,
                        placeId: place_id,
                        lat: lat(),
                        lng: lng()
                    }
                });
            }
            setLocating(false);
        });
    };

    return (
        <div>
            <Autocomplete
                freeSolo
                className={classes.autocomplete}
                value={autocomplete}
                getOptionLabel={option => (option ? option.description : "")}
                options={results}
                loading={loading}
                getOptionSelected={option =>
                    option.place_id === autocomplete.place_id
                }
                onChange={(_, output) => {
                    setAutocomplete(output);
                    if (output) {
                        handleGeocode({ placeId: output.place_id });
                    }
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        label="Find Address"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
            <Grid container spacing={1}>
                {fields.map(({ label, name }, i) => (
                    <Grid
                        key={name}
                        item
                        sm={fieldWidths[i]}
                        xs={xsFieldWidths[i]}
                    >
                        <TextField
                            key={name}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            label={label}
                            value={state[name] || ""}
                            onChange={handleChange(name)}
                        />
                    </Grid>
                ))}
                <Grid className={classes.iconButton} item sm={1} xs={3}>
                    {state.placeId ? (
                        <MapLink address={state}>
                            <IconButton color="primary">
                                <Room fontSize="large" />
                            </IconButton>
                        </MapLink>
                    ) : (
                        <IconButton
                            onClick={handleLocate}
                            disabled={!canLocate || locating}
                        >
                            <NotListedLocation fontSize="large" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default AddressPicker;
