import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import Field from "./Field";

const Choice = ({
    name,
    autoFocus,
    onChange,
    value,
    options,
    label,
    index,
    disabled
}) => (
    <Field
        index={index}
        label={label}
        name={name}
        onChange={onChange}
        fullWidth
    >
        <Autocomplete
            autoFocus={autoFocus}
            id={name}
            name={name}
            options={options || []}
            onChange={(_, value) => {
                onChange({
                    target: {
                        name,
                        value
                    }
                });
            }}
            value={value === undefined ? null : value}
            disabled={disabled}
            renderInput={params => <TextField {...params} variant="outlined" />}
        />
    </Field>
);

export default Choice;
