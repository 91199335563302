import React from "react";

const MapLink = ({ address, children }) => (
    <a
        rel="noopener noreferrer"
        href={`https://www.google.com/maps/search/?api=1&query=${address.lat},${address.lng}&query_place_id=${address.placeId}`}
        target="_blank"
    >
        {children}
    </a>
);

export default MapLink;
