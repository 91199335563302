import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import {
    BigError,
    ErrorMessage,
    Form,
    FullScreenDialog,
    Loading,
    Overlay,
    SaveCancel
} from "../../components";
import { initForm } from "../../components/Form";
import useApi from "../../useApi";
import useDownload from "../../useDownload";
import useUser from "../../useUser";
import { nullify } from "../../util";
import DocumentEditor from "../DocumentEditor";
import AddForm from "./AddForm";
import AdditionalDocuments from "./AdditionalDocuments";
import ConfirmComplete from "./ConfirmComplete";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmFinish from "./ConfirmFinish";
import exportForm from "./exportForm";
import exportJobSheet from "./exportJobSheet";
import Section from "./Section";
import Summary from "./Summary";

const JobSheetContents = ({ id, onClose }) => {
    const { isAdmin } = useUser();
    const documentsEndpoint = `jobsheets/${id}/documents`;
    const formsEndpoint = `jobsheets/${id}/forms`;
    const { single: jobsheets, post, response } = useCallback(
        useApi("jobsheets"),
        []
    );
    const { single: workpacks } = useCallback(useApi("workpacks"), []);
    const { single: forms, response: formsResponse } = useCallback(
        useApi("forms"),
        []
    );
    const { download: branding } = useApi("branding");
    const {
        data: jobSheetForms,
        single: getJobSheetForm,
        add: addJobSheetForm,
        update: updateJobSheetForm,
        remove: deleteJobSheetForm,
        response: jobSheetFormResponse,
        loadError: loadDocumentsError
    } = useApi(formsEndpoint, true);

    const {
        data: jobSheetDocuments,
        remove: deleteJobSheetDocument,
        _add: _addJobSheetDocument,
        response: jobSheetDocumentResponse,
        loadError: loadFormsError,
        url: addJobSheetDocument
    } = useApi(documentsEndpoint, true);

    const [contents, setContents] = useState();
    const [message, setMessage] = useState();
    const [current, setCurrent] = useState();
    const [deletingForm, setDeletingForm] = useState();
    const [deletingDocument, setDeletingDocument] = useState();
    const [finishingJob, setFinishingJob] = useState();
    const [signingOffJob, setSigningOffJob] = useState();
    const [criticalError, setCriticalError] = useState(false);
    const [addingForm, setAddingForm] = useState(false);
    const [addingDocument, setAddingDocument] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const libraryDownload = useDownload(setMessage);
    const jobSheetDownload = useDownload(setMessage, documentsEndpoint);

    const canChangeForms = isAdmin || !contents?.jobsheet.finished;
    const canAddDocument = isAdmin || !contents?.jobsheet.signedOff;

    const handleFinishJob = async () => {
        const jobsheet = await post(`/${id}/finish`, { id });
        setFinishingJob(null);
        if (response.ok) {
            setContents({ ...contents, jobsheet });
        } else {
            setMessage(
                "An error has occurred while trying to finish the job sheet"
            );
        }
    };

    const handleSignOffJob = async () => {
        const jobsheet = await post(`/${id}/signoff`, { id });
        setSigningOffJob(null);
        if (response.ok) {
            setContents({ ...contents, jobsheet });
        } else {
            setMessage(
                "An error has occurred while trying to sign off the job sheet"
            );
        }
    };

    const getLogo = async () => {
        const arrayBuffer = await branding("logo.png");
        return new Promise((resolve, reject) => {
            const blob = new Blob([arrayBuffer]);
            const reader = new FileReader();
            reader.onload = e => {
                // FIX #45. IOS seems to return "data:;" at the start of the data url rather than "data:application/octet-stream;"
                const dataUrl = e.target.result.replace(
                    /^data:;/,
                    "data:application/octet-stream;"
                );
                resolve(dataUrl);
            };
            reader.onerror = _ => reject();
            reader.readAsDataURL(blob);
        });
    };

    const handleExportSummary = async () => {
        const branding = await getLogo();
        const formsBySection = jobSheetForms.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.form.section]: [
                    ...(acc[curr.form.section] || []),
                    { id: curr.id, name: curr.name }
                ]
            }),
            {}
        );
        const sections = [
            ...contents.workpack.sections.map(s => ({
                name: s.name,
                documents: [...s.documents, ...(formsBySection[s.name] || [])]
            })),
            {
                name: "Additional Documents",
                documents: jobSheetDocuments
            }
        ].reduce(
            (acc, curr) =>
                curr.documents.length > 0 ? [...acc, curr] : [...acc],
            []
        );
        exportJobSheet(contents.jobsheet, sections, branding);
    };

    const handleExportForm = async formId => {
        const form = await getJobSheetForm(formId);
        const logo = await getLogo();
        if (jobSheetFormResponse.ok) {
            exportForm(form, logo);
        } else {
            setMessage("An error has occurred while trying to export the form");
        }
    };

    const handleDeleteJobSheetForm = async () => {
        await deleteJobSheetForm(deletingForm);
        setDeletingForm(null);
        if (!jobSheetFormResponse.ok) {
            setMessage("An error has occurred while trying to delete the form");
        }
    };

    const handleDeleteJobSheetDocument = async () => {
        await deleteJobSheetDocument(deletingDocument);
        setDeletingDocument(null);
        if (!jobSheetDocumentResponse.ok) {
            setMessage(
                "An error has occurred while trying to delete the document"
            );
        }
    };

    const handleAddDifferentForm = async ({ id }) => {
        await handleAddForm(id);
        setAddingForm(false);
    };

    const handleDocumentAdded = d => {
        _addJobSheetDocument(d);
    };

    const handleAddingDocument = () => {
        setAddingDocument(true);
    };

    const handleCloseAddDocument = () => {
        setAddingDocument(false);
    };

    const handleAddForm = async formId => {
        setOverlay(true);
        const form = await forms(formId);
        if (formsResponse.ok) {
            setCurrent(initForm(id, form));
        } else {
            setMessage("An error has occurred while trying to add the form");
        }
        setOverlay(false);
    };

    const handleEditForm = async jobSheetFormId => {
        setOverlay(true);
        const jobSheetForm = await getJobSheetForm(jobSheetFormId);
        if (jobSheetFormResponse.ok) {
            setCurrent(jobSheetForm);
        } else {
            setMessage("An error has occurred while trying to edit the form");
        }
        setOverlay(false);
    };

    const handleSaveForm = async jobSheetForm => {
        if (jobSheetForm.id) {
            await updateJobSheetForm(jobSheetForm);
        } else {
            await addJobSheetForm(jobSheetForm);
        }
        if (jobSheetFormResponse.ok) {
            setCurrent(null);
        }
        return jobSheetFormResponse.ok;
    };

    const handleCloseAddForm = () => setAddingForm(false);

    useEffect(() => {
        const load = async () => {
            const jobsheet = await jobsheets(id);
            if (jobsheet) {
                const workpack = await workpacks(jobsheet.workPack.id);
                if (workpack) {
                    setContents({ jobsheet, workpack });
                } else {
                    setCriticalError(true);
                }
            } else {
                setCriticalError(true);
            }
        };
        load();
    }, [id, jobsheets, workpacks]);

    const allForms = contents?.workpack.sections.reduce(
        (acc, current) => [...acc, ...current.forms.map(f => f.id)],
        []
    );
    const otherJobSheetForms =
        jobSheetForms && allForms
            ? jobSheetForms.filter(f => !allForms.includes(f.form.id))
            : [];

    const sections = contents?.workpack.sections.map(section => ({
        name: section.name,
        forms: section.forms.map(form => ({
            ...form,
            jobSheetForms: jobSheetForms?.filter(f => f.form.id === form.id)
        })),
        additionalForms: otherJobSheetForms.filter(
            f => f.form.section === section.name
        ),
        documents: section.documents
    }));

    const isLoaded = contents && jobSheetForms && jobSheetDocuments;

    return loadFormsError || loadDocumentsError || criticalError ? (
        <BigError />
    ) : isLoaded ? (
        <>
            <Overlay open={overlay} />
            <Form
                onCancel={nullify(setCurrent)}
                onSave={handleSaveForm}
                item={current}
                canChangeForms={canChangeForms}
            />
            {addingForm && (
                <AddForm
                    onSave={handleAddDifferentForm}
                    onClose={handleCloseAddForm}
                />
            )}
            {addingDocument && (
                <FullScreenDialog
                    open={true}
                    onCancel={handleCloseAddDocument}
                    title={`Add Doccument - ${contents.jobsheet.jobNumber}`}
                    fill
                >
                    <DocumentEditor
                        onAdd={handleDocumentAdded}
                        onCancel={handleCloseAddDocument}
                        endpoint={addJobSheetDocument}
                        meta={{
                            jobSheetId: contents.jobsheet.id
                        }}
                    />
                </FullScreenDialog>
            )}
            <ErrorMessage message={message} onClose={nullify(setMessage)} />
            <ConfirmFinish
                item={finishingJob}
                onConfirm={handleFinishJob}
                onCancel={nullify(setFinishingJob)}
            />
            <ConfirmComplete
                item={signingOffJob}
                onConfirm={handleSignOffJob}
                onCancel={nullify(setSigningOffJob)}
            />
            <ConfirmDelete
                item={deletingForm}
                onConfirm={handleDeleteJobSheetForm}
                onCancel={nullify(setDeletingForm)}
                noun="form"
            />
            <ConfirmDelete
                item={deletingDocument}
                onConfirm={handleDeleteJobSheetDocument}
                onCancel={nullify(setDeletingDocument)}
                noun="document"
            />
            <Summary
                onFinishJob={() => setFinishingJob(contents.jobsheet)}
                onSignOffJob={() => setSigningOffJob(contents.jobsheet)}
                onExportSummary={handleExportSummary}
                {...contents.jobsheet}
            />
            {sections?.map((section, index) => (
                <Section
                    key={`section-${index}`}
                    {...section}
                    onAddForm={handleAddForm}
                    onEditForm={handleEditForm}
                    onDeleteForm={setDeletingForm}
                    onDownload={libraryDownload}
                    onExportPdf={handleExportForm}
                    canChangeForms={canChangeForms}
                />
            ))}
            {(canAddDocument || jobSheetDocuments.length > 0) && (
                <AdditionalDocuments
                    documents={jobSheetDocuments}
                    onDownload={jobSheetDownload}
                    onAddDocument={handleAddingDocument}
                    onDeleteDocument={setDeletingDocument}
                    canAddDocument={canAddDocument}
                />
            )}
            {canChangeForms && (
                <Button
                    onClick={() => setAddingForm(true)}
                    color="primary"
                    startIcon={<Add />}
                >
                    Add a form
                </Button>
            )}
            <SaveCancel onClose={onClose} />
        </>
    ) : (
        <Loading />
    );
};

export default JobSheetContents;
