import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { DocumentIcon } from "../../components";
import useUser from "../../useUser";
import { displaySize } from "../../util";

const useStyles = makeStyles(theme => ({
    item: {
        alignItems: "flex-start"
    },
    itemAvatar: {
        marginTop: theme.spacing()
    },
    delete: {
        [theme.breakpoints.down("xs")]: {
            right: 0
        }
    },
    document: {
        "& *": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }
}));

const DocumentList = ({
    documents,
    onDownload,
    hideHeader,
    onDeleteDocument
}) => {
    const classes = useStyles();
    const { isAdmin } = useUser();

    return documents.length ? (
        <List>
            {!hideHeader && (
                <ListSubheader disableSticky={true}>Documents</ListSubheader>
            )}
            {documents.map(({ id, name, extension, size }) => (
                <div key={`document-${id}`}>
                    <ListItem
                        onClick={onDownload({
                            id,
                            name,
                            extension
                        })}
                        key={id}
                        className={classes.item}
                        button
                    >
                        <ListItemAvatar className={classes.itemAvatar}>
                            <DocumentIcon extension={extension} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={name}
                            className={classes.document}
                            secondary={displaySize(size)}
                        />
                        {onDeleteDocument && isAdmin && (
                            <ListItemSecondaryAction className={classes.delete}>
                                <IconButton
                                    onClick={() => onDeleteDocument(id)}
                                >
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </div>
            ))}
        </List>
    ) : null;
};

export default DocumentList;
