import Checkbox from "@material-ui/core/Checkbox";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import React from "react";
import FormikDropdown from "./FormikDropdown";

const FormikMultiselect = ({ name, label, options, formik }) => (
    <FormikDropdown
        name={name}
        label={label}
        formik={formik}
        options={options}
        multiple
        disableCloseOnSelect
        getOptionSelected={(option, value) => value === option}
        getOptionLabel={option => option}
        renderOption={(option, { selected }) => (
            <React.Fragment>
                <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option}
            </React.Fragment>
        )}
    />
);

export default FormikMultiselect;
