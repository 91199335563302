import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";

const ErrorMessage = ({ message, onClose }) => (
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!message}
        autoHideDuration={5000}
        onClose={onClose}
    >
        <Alert onClose={onClose} severity="error">
            {message}
        </Alert>
    </Snackbar>
);

export default ErrorMessage;
