import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import React from "react";
import { fromDateString, toDateString } from "../dates";

const useStyles = makeStyles(theme => ({
    root: {
        margin: `${theme.spacing(1.5)}px 0`,
        width: "100%"
    },
    error: {
        marginBottom: theme.spacing(0.5)
    }
}));

const FormikDatePicker = ({ formik, name, label, className }) => {
    const classes = useStyles();
    const error = formik.touched[name] && formik.errors[name];
    return (
        <DatePicker
            className={clsx(classes.root, error && classes.error, className)}
            name={name}
            format="PPP"
            inputVariant="outlined"
            label={label}
            onBlur={value => {
                formik.handleBlur({
                    target: {
                        name,
                        value
                    }
                });
            }}
            onChange={value => {
                formik.handleChange({
                    target: {
                        name,
                        value: toDateString(value)
                    }
                });
            }}
            error={!!error}
            helperText={error}
            value={fromDateString(formik.values[name])}
        />
    );
};

export default FormikDatePicker;
