import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 280
    },
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    content: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing()
        }
    },
    fill: {
        height: "100%",
        padding: 0
    },
    grey: {
        backgroundColor: theme.palette.background.default
    },
    scrollFix: {
        overflowY: "inherit" // Fixes the warning raised by drag/drop due to multiple elements being scrollable
    },
    dialogTitle: {
        padding: 0
    }
}));

const FullScreenDialog = ({ title, open, onCancel, children, fill, grey }) => {
    const classes = useStyles();
    //    const dialogClasses = grey ? { paper: classes.grey } : null;
    const dialogClasses = {
        paper: clsx(classes.scrollFix, grey && classes.grey)
    };
    return (
        <Dialog
            className={classes.root}
            classes={dialogClasses}
            fullScreen
            open={open}
        >
            <DialogTitle className={classes.dialogTitle}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onCancel}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            className={classes.title}
                        >
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent
                dividers
                className={fill ? classes.fill : classes.content}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default FullScreenDialog;
