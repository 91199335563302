import { Edit } from "@material-ui/icons";
import React from "react";
import TextInput from "../inputs/TextInput";
import makeEditor from "../makeEditor";
import makeInput from "../makeInput";

const text = {
    name: "Text",
    type: "text",
    icon: <Edit />,
    acceptsFocus: true,
    init: _ => ({ value: "" }),
    editor: makeEditor("Text label"),
    render: makeInput(TextInput)
};

export default text;
