import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { Overlay } from "../../components";
import useApi from "../../useApi";

const useStyles = makeStyles(theme => ({
    content: {
        padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`
    },
    autocomplete: {
        minWidth: 500,
        [theme.breakpoints.down("xs")]: {
            minWidth: 200
        }
    }
}));

const bySectionThenName = (a, b) =>
    -b.section.localeCompare(a.section) || -b.name.localeCompare(a.name);

const AddForm = ({ onClose, onSave }) => {
    const classes = useStyles();
    const { data } = useApi("forms", true);
    const [selectedForm, setSelectedForm] = useState();
    const [saving, setSaving] = useState(false);

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle onClose={onClose}>Add Form</DialogTitle>
            <DialogContent className={classes.content} dividers>
                <Overlay open={saving} />
                <Autocomplete
                    className={classes.autocomplete}
                    onChange={(_, value) => {
                        setSelectedForm(value);
                    }}
                    options={data?.sort(bySectionThenName) || []}
                    groupBy={option => option.section}
                    getOptionLabel={option => option.name}
                    loading={!data}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Form"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {!data ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={!selectedForm}
                    onClick={async () => {
                        setSaving(true);
                        try {
                            await onSave(selectedForm);
                        } catch (e) {
                            setSaving(false);
                            throw e;
                        }
                    }}
                    variant="contained"
                    color="primary"
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddForm;
