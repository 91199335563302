import {
    AppBar,
    Avatar,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@material-ui/core";
import {
    AccountCircle,
    ExitToApp,
    Home,
    Menu as MenuIcon,
    Person
} from "@material-ui/icons";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useApi from "../../useApi";
import useFeatures from "../../useFeatures";
import useUser from "../../useUser";
import { getInitials, getName } from "../../util";
import Search from "../Search";
import SidebarItem from "./SidebarItem";
import Tenant from "./Tenant";

const drawerWidth = 240;
const sidebar = "lg";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    drawerNavigation: {
        [theme.breakpoints.up(sidebar)]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawer: {
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
    appBar: {
        [theme.breakpoints.up(sidebar)]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(sidebar)]: {
            display: "none"
        }
    },
    toolbar: {
        ...theme.mixins.toolbar,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2)
    },
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing()
        },
        minWidth: 320
    },
    title: {
        flex: 1
    },
    logo: {
        marginLeft: theme.spacing(),
        height: 36,
        [theme.breakpoints.down("xs")]: {
            height: 28
        }
    },
    links: {
        flex: 1
    },
    divider: {
        margin: `${theme.spacing()}px 0`
    },
    avatarContainer: {
        display: "flex",
        padding: theme.spacing(2),
        outline: "none"
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: theme.palette.primary.main
    },
    avatarDetails: {
        margin: `0 ${theme.spacing()}px`,
        maxWidth: 250,
        "& *": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }
}));

const Layout = ({ children }) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const { isAdmin, signOut, email, givenName, familyName } = useUser();
    const { data: metadata } = useApi("metadata", isAdmin);
    const features = useFeatures();
    const { state, pathname } = useLocation();
    const title = state?.text;
    const feature = features.find(f => f.url === pathname);
    const fullName = getName({ givenName, familyName });

    const Title = () => {
        return title || <Hidden lgUp>Riskd</Hidden>;
    };

    useEffect(() => {
        if (metadata) {
            console.info(`
                Tenant                  : ${metadata.tenant}
                Server Product Version  : ${metadata.productVersion}
                Server Assembly Version : ${metadata.assemblyVersion}
                Server File Version     : ${metadata.fileVersion}
                Client App Version      : ${process.env.REACT_APP_VERSION}
                `);
        }
    }, [metadata]);

    const handleMenu = event => {
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    const handleSignout = () => {
        handleClose();
        signOut();
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const drawer = (
        <div className={classes.drawer}>
            <div className={classes.toolbar}>
                <img
                    className={classes.logo}
                    alt="Riskd logo"
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                />
            </div>
            <Divider />
            <List className={classes.links}>
                <SidebarItem
                    onClick={closeDrawer}
                    url="/"
                    text="Home"
                    icon={<Home />}
                    useDefaultHeading
                />
                {features
                    .filter(({ hidden }) => !hidden)
                    .map(({ url, name, search, group, target, Icon }) => (
                        <Fragment key={`${url}/${search}`}>
                            {group && <Divider className={classes.divider} />}
                            <SidebarItem
                                onClick={closeDrawer}
                                target={target}
                                search={search}
                                url={url}
                                text={name}
                                icon={<Icon />}
                            />
                        </Fragment>
                    ))}

                {/* <Divider className={classes.divider} />
                <SidebarItem
                    onClick={closeDrawer}
                    url="/account"
                    text="Account"
                    icon={<Person />}
                /> */}
            </List>
            <Tenant {...metadata} />
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Hidden xsDown>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            noWrap
                        >
                            <Title />
                        </Typography>
                    </Hidden>
                    {!!feature && (
                        <Search
                            className={classes.title}
                            placeholder={`Search ${title || ""}`}
                        />
                    )}
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle fontSize="large" />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={menuAnchor}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={Boolean(menuAnchor)}
                            onClose={handleClose}
                        >
                            <div className={classes.avatarContainer}>
                                <Avatar
                                    title={fullName}
                                    className={classes.avatar}
                                >
                                    {getInitials({
                                        givenName,
                                        familyName
                                    })}
                                </Avatar>
                                <div className={classes.avatarDetails}>
                                    <Typography variant="h6">
                                        {fullName}
                                    </Typography>
                                    <Typography variant="body2">
                                        {email}
                                    </Typography>
                                </div>
                            </div>
                            <Divider />
                            <MenuItem
                                onClick={handleClose}
                                component={NavLink}
                                to={{
                                    pathname: "/account",
                                    state: { text: "Account" }
                                }}
                            >
                                <ListItemIcon>
                                    <Person fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Account" />
                            </MenuItem>
                            <MenuItem onClick={handleSignout}>
                                <ListItemIcon>
                                    <ExitToApp fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Sign out" />
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawerNavigation}>
                <Hidden lgUp>
                    <Drawer
                        variant="temporary"
                        open={drawerOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden mdDown>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
};

export default Layout;
