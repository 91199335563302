import choice from "./choice";
import date from "./date";
import hazard from "./hazard";
import heading from "./heading";
import multi from "./multi";
import risk from "./risk";
import text from "./text";

const fields = [text, multi, date, choice, heading, hazard, risk];

const fieldLookup = {
    text,
    multi,
    date,
    choice,
    heading,
    hazard,
    risk
};

const getFieldDefinition = field => {
    const fieldDefinition = fieldLookup[field.type];
    if (!fieldDefinition)
        throw new Error(
            `Field '${field.name}' of type '${field.type}' cannot be found. Add a definition for '${field.type}' to fields`
        );
    return fieldDefinition;
};

export { fields };
export default getFieldDefinition;
