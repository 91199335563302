import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormikSwitch, FormikTextField, SaveCancel } from "../components";
import FormikAddressPicker from "../components/FormikAddressPicker";

const CustomerEditor = ({ item, onCancel, onSave }) => {
    const formik = useFormik({
        initialValues: item,
        validationSchema: Yup.object({
            name: Yup.string().required("Customer name is required")
        }),
        onSubmit: onSave
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikTextField
                formik={formik}
                name="name"
                label="Name"
                autoFocus
            />
            <FormikSwitch formik={formik} name="active" label="Active" />
            <FormikAddressPicker formik={formik} name="invoiceAddress" />
            <SaveCancel onCancel={onCancel} />
        </form>
    );
};

export default CustomerEditor;
