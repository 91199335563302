import {
    AccountBalance,
    Assessment,
    Assignment,
    Business,
    Folder,
    InsertDriveFile,
    ListAlt,
    People,
    Report,
    VerifiedUser,
    Work
} from "@material-ui/icons";
import React from "react";
import {
    Customers,
    Depots,
    Forms,
    JobSheets,
    Library,
    Users,
    WorkPacks
} from "./pages";
import roles, { SYSTEM_ADMINISTRATOR } from "./roles";
import useUser from "./useUser";

const features = [
    {
        url: "/documents",
        name: "Documents",
        description: "Library of policies, processes and procedures",
        Icon: Folder,
        render: () => <Library />,
        roles
    },
    {
        url: "/users",
        name: "Users",
        description: "Manage users and permissions",
        group: true,
        Icon: People,
        render: () => <Users />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/customers",
        name: "Customers",
        description: "Manage customers and customer locations",
        Icon: AccountBalance,
        render: () => <Customers />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/depots",
        name: "Depots",
        description: "Manage depots and depot locations",
        Icon: Business,
        render: () => <Depots />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/forms",
        search: "q=risk",
        name: "Risk Assessments",
        description: "Manage risk assessments",
        group: true,
        Icon: ListAlt,
        render: () => <Forms />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/forms",
        search: "q=incident",
        name: "Incident Reporting",
        description: "Manage incidents",
        Icon: Report,
        render: () => <Forms />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/forms",
        search: "q=audit",
        name: "Audit",
        description: "Manage audits",
        Icon: VerifiedUser,
        render: () => <Forms />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/forms",
        name: "Form Builder",
        description: "Build and manage general data entry forms",
        Icon: Assignment,
        render: () => <Forms />,
        roles: [SYSTEM_ADMINISTRATOR]
    },

    {
        url: "/workpacks",
        name: "Work Packs",
        description:
            "Combine processes, documents, forms, risk and COSHH assessments",
        group: true,
        Icon: Work,
        render: () => <WorkPacks />,
        roles: [SYSTEM_ADMINISTRATOR]
    },
    {
        url: "/jobsheets",
        name: "Job Sheets",
        description:
            "Ensure standard processes are followed for repeatable jobs",
        Icon: InsertDriveFile,
        render: () => <JobSheets />,
        roles
    },
    {
        url:
            "https://app.powerbi.com/groups/9d500794-f218-4bdd-8196-97e8c2ce7a5e/reports/6816cad3-5b16-4867-ac1f-132f4949c73b/ReportSection4fc256bc8bbbaf539815",
        name: "Reporting",
        description: "Gain insights on your data",
        Icon: Assessment,
        target: "_blank",
        group: true,
        render: () => <Forms />,
        roles: [SYSTEM_ADMINISTRATOR]
    }
];

const useFeatures = () => {
    const { roles } = useUser();
    return features.filter(f => f.roles?.some(r => roles.includes(r)));
};

export default useFeatures;
