import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    tenant: {
        textAlign: "center",
        whiteSpace: "pre-line"
    }
});

const Tenant = ({ tenant, productVersion }) => {
    const classes = useStyles();
    return tenant ? (
        <div
            title={`Tenant: ${tenant}\nAPI Version: ${productVersion}\nWeb Version: ${process.env.REACT_APP_VERSION}`}
            className={classes.tenant}
        >
            <Divider />
            <Typography variant="caption" gutterBottom>
                {tenant}
            </Typography>
        </div>
    ) : null;
};

export default Tenant;
