import { IconButton, makeStyles } from "@material-ui/core";
import { Delete, DragIndicator } from "@material-ui/icons";
import React from "react";
import { FormikTextField } from "../../components";

const useStyles = makeStyles(theme => ({
    fieldGroup: {
        display: "flex",
        alignItems: "center"
    },
    fieldActions: {
        display: "flex",
        alignSelf: "flex-start",
        marginTop: theme.spacing(),
        marginLeft: theme.spacing(0.5)
    },
    dragIndicator: {
        marginTop: -16
    }
}));

const getFormikValue = (obj, index, validationProperties) => {
    const fields = obj?.fields;
    return (
        fields &&
        fields.length > index &&
        fields[index] &&
        validationProperties.map(p => fields[index][p]).find(p => p)
    );
};

const makeEditor = (label, action, validationProperties = ["name"]) => ({
    formik,
    arrayHelpers,
    index
}) => {
    const classes = useStyles();
    const isTouched = !!getFormikValue(
        formik.touched,
        index,
        validationProperties
    );
    const error = getFormikValue(formik.errors, index, validationProperties);
    return (
        <div key={index} className={classes.fieldGroup}>
            <DragIndicator className={classes.dragIndicator} color="action" />
            <FormikTextField
                formik={formik}
                name={`fields[${index}].name`}
                value={formik.values.fields[index].name}
                label={label}
                placeholder="Enter a name for this field"
                error={isTouched && error}
            />
            <div className={classes.fieldActions}>
                {action}
                <IconButton
                    onClick={() => arrayHelpers.remove(index)}
                    aria-label="delete"
                >
                    <Delete />
                </IconButton>
            </div>
        </div>
    );
};

export default makeEditor;
