import { amber, green, red } from "@material-ui/core/colors";
import { format } from "date-fns";
import logo from "../../logo";
import makePdf from "../../makePdf";
import { getName } from "../../util";

const empty = "N/A";

const getDate = dt => (dt ? format(new Date(dt), "PPP") : empty);

const makeField = (getFieldValue = f => f.value) => f => [
    { text: f.name, style: "question" },
    { text: getFieldValue(f) || empty, style: "answer" }
];

const getSvg = value =>
    `<svg viewBox="0 0 32 32">${
        value
            ? `<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"/>`
            : `<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>`
    }</svg>`;

const getRisk = rating =>
    rating >= 15
        ? ["High", red[500]]
        : rating <= 6
        ? ["Low", green[500]]
        : ["Medium", amber[500]];

const makeRiskField = () => f => {
    const likelihood = f.likelihood || 0;
    const severity = f.severity || 0;
    const risk = likelihood * severity;
    const [text, background] = getRisk(risk);
    return [
        { text: f.name, style: "question" },
        {
            columns: [
                { text: "Risk:", width: "auto" },
                {
                    text,
                    width: "auto",
                    background,
                    color: "#ffffff",
                    margin: [4, 0, 2, 0]
                },
                {
                    text: `(${risk})`
                }
            ],
            style: "riskAnswer"
        },
        ...(f.controlMeasures || []).map(cm => ({
            columns: [
                {
                    width: 22,
                    svg: getSvg(cm.value)
                },
                {
                    text: cm.name,
                    style: "controlMeasure"
                }
            ]
        })),
        { text: "", style: "answer" }
    ];
};

const fieldMap = {
    heading: f => ({ text: f.name, style: "sectionHeading" }),
    date: makeField(f => getDate(f.value)),
    risk: makeRiskField()
};

const getValue = field => {
    const fn = fieldMap[field.type] || makeField();
    return fn(field);
};

const getFields = fields => fields.map(getValue).flat();

const exportForm = (f, brandLogo) => {
    const dd = {
        header: {
            image: brandLogo,
            width: 150,
            alignment: "right",
            margin: [4, 8]
        },
        footer: {
            image: logo,
            width: 64,
            alignment: "center"
        },
        content: [
            {
                text: f.form.name,
                style: "heading"
            },
            {
                text: f.form.section,
                style: "subheading"
            },
            {
                text: getDate(f.modified),
                style: "subheading"
            },
            {
                text: getName(f.modifiedBy),
                style: "subheading",
                margin: [0, 0, 0, 20]
            },
            ...getFields(f.fields)
        ],
        styles: {
            heading: {
                fontSize: 20,
                bold: true,
                alignment: "center",
                margin: [0, 34, 0, 0]
            },
            subheading: {
                fontSize: 14,
                lineHeight: 1.2,
                margin: [0, 2],
                opacity: 0.6,
                alignment: "center"
            },
            sectionHeading: {
                fontSize: 18,
                bold: true,
                margin: [0, 20]
            },
            question: {
                fontSize: 12,
                bold: true,
                lineHeight: 1.2,
                margin: [0, 2],
                opacity: 0.6
            },
            answer: {
                margin: [0, 0, 0, 15]
            },
            riskAnswer: {
                margin: [0, 0, 0, 5],
                bold: true
            },
            controlMeasure: {
                fontSize: 14
            }
        },
        defaultStyle: {
            fontSize: 16,
            bold: false,
            lineHeight: 1.1
        }
    };
    makePdf(dd, `${f.form.name}-${f.modified.split(".")[0]}`);
};

export default exportForm;
