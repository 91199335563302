import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import DocumentList from "./DocumentList";

const useStyles = makeStyles(theme => ({
    section: {
        margin: `${theme.spacing(2)}px 0`,
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down("xs")]: {
            padding: `${theme.spacing(3)}px 0`,
            paddingRight: theme.spacing(0.5)
        }
    },
    heading: {
        padding: `0 ${theme.spacing(2)}px`
    },
    add: {
        margin: theme.spacing()
    }
}));

const AdditionalDocuments = ({
    documents,
    onDownload,
    onAddDocument,
    onDeleteDocument,
    canAddDocument
}) => {
    const classes = useStyles();

    return (
        <Paper className={classes.section}>
            <Typography className={classes.heading} variant="h6">
                Additional Documents
            </Typography>
            <DocumentList
                documents={documents}
                onDownload={onDownload}
                onDeleteDocument={onDeleteDocument}
                hideHeader
            />
            {canAddDocument && (
                <Button
                    className={classes.add}
                    onClick={onAddDocument}
                    color="primary"
                    startIcon={<Add />}
                >
                    Add document
                </Button>
            )}
        </Paper>
    );
};

export default AdditionalDocuments;
