import { makeStyles } from "@material-ui/core";
import React from "react";
import RiskRating from "./RiskRating";
import RiskSlider from "./RiskSlider";

const useStyles = makeStyles({
    rating: {
        textAlign: "right"
    }
});

const RiskScore = ({
    likelihood,
    severity,
    onLikelihoodChange,
    onSeverityChange
}) => {
    const classes = useStyles();
    return (
        <div>
            <RiskSlider
                onChange={onLikelihoodChange}
                value={likelihood}
                label="Likelihood"
            />
            <RiskSlider
                onChange={onSeverityChange}
                value={severity}
                label="Severity"
            />
            <div className={classes.rating}>
                <RiskRating likelihood={likelihood} severity={severity} />
            </div>
        </div>
    );
};

export default RiskScore;
