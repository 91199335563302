import React from "react";
import { BigError, Info, Loading } from "../components";

const DataView = ({ data, error, component, empty }) =>
    error ? (
        <BigError />
    ) : !data ? (
        <Loading />
    ) : !data.length ? (
        <Info Icon={empty.Icon} title={empty.title} subtitle={empty.subtitle} />
    ) : (
        component({ data })
    );

export default DataView;
