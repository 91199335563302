import { Subject } from "@material-ui/icons";
import React from "react";
import TextInput from "../inputs/TextInput";
import makeEditor from "../makeEditor";
import makeInput from "../makeInput";

const multi = {
    name: "Multitext",
    type: "multi",
    icon: <Subject />,
    acceptsFocus: true,
    init: _ => ({ value: "" }),
    editor: makeEditor("Multitext label"),
    render: makeInput(TextInput, _ => ({ rows: 4 }))
};

export default multi;
