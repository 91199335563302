import { format } from "date-fns";
import logo from "../../logo";
import makePdf from "../../makePdf";
import { getName as _getName } from "../../util";

const noBlanks = part => part && part.length > 0;
const empty = "N/A";

const getAddress = ({ number, street1, street2, city, county, postcode }) => {
    const firstLine = [number, street1].filter(noBlanks).join(" ");
    const parts = [firstLine, street2, city, county, postcode].filter(noBlanks);
    return parts.join("\n") || empty;
};

const makeGetDate = f => dt => (dt ? format(new Date(dt), f) : empty);
const getDate = makeGetDate("PPP");
const getDateTime = makeGetDate("PPPp");

const getName = (user, fallback = empty) => (user ? _getName(user) : fallback);

const exportJobSheet = (j, sections, brandLogo) => {
    const dd = {
        header: {
            image: brandLogo,
            width: 150,
            alignment: "right",
            margin: [4, 8]
        },
        footer: {
            image: logo,
            width: 64,
            alignment: "center"
        },
        content: [
            {
                text: j.jobNumber,
                style: "header",
                margin: [0, 34, 0, 20]
            },
            {
                columns: [
                    [
                        { text: "Customer", style: "subheader" },
                        { text: j.customer.name, style: "text" },
                        { text: "Site address", style: "subheader" },
                        {
                            text: getAddress(j.siteAddress),
                            style: "text"
                        },
                        { text: "Contact", style: "subheader" },
                        { text: j.contact?.name || empty },
                        { text: j.contact?.phone, style: "text" },
                        { text: "Depot", style: "subheader" },
                        { text: j.depot.name, style: "text" },
                        { text: "Team Leader", style: "subheader" },
                        {
                            text: getName(j.teamLeader),
                            style: "text"
                        },
                        { text: "Team Members", style: "subheader" },
                        {
                            text:
                                j.teamMembers.map(getName).join("\n") || empty,
                            style: "text"
                        }
                    ],
                    [
                        {
                            stack: [
                                {
                                    text: "Nearest hospital",
                                    style: "subheader"
                                },
                                {
                                    text: getAddress(j.nearestHospital),
                                    style: "text"
                                },
                                { text: "Expected start", style: "subheader" },
                                { text: getDate(j.startDate), style: "text" },
                                { text: "Started", style: "subheader" },
                                { text: getDateTime(j.started) },
                                { text: getName(j.startedBy), style: "text" },
                                { text: "Expected finish", style: "subheader" },
                                { text: getDate(j.finishDate), style: "text" },
                                { text: "Finished", style: "subheader" },
                                { text: getDateTime(j.finished) },
                                {
                                    text: getName(j.finishedBy, null),
                                    style: "text"
                                },
                                { text: "Signed off", style: "subheader" },
                                { text: getDateTime(j.signedOff) },
                                {
                                    text: getName(j.signedOffBy, null),
                                    style: "text"
                                }
                            ],
                            alignment: "right"
                        }
                    ]
                ]
            },
            { text: "Work Pack", style: "subheader" },
            { text: j.workPack.name, style: "text" },
            { text: "Scope", style: "subheader" },
            { text: j.scope || empty },
            ...sections.map((s, i) => [
                {
                    text: s.name,
                    style: "subheader",
                    fontSize: 13,
                    pageBreak: i === 0 ? "before" : undefined,
                    margin: i === 0 ? [0, 40, 0, 4] : [0, 20, 0, 4]
                },
                {
                    ul: s.documents.map(d => [{ text: d.name }]),
                    style: "document"
                }
            ])
        ],
        styles: {
            header: {
                fontSize: 20,
                bold: true,
                alignment: "center"
            },
            subheader: {
                fontSize: 12,
                bold: true,
                lineHeight: 1.2,
                margin: [0, 2],
                opacity: 0.6
            },
            text: {
                margin: [0, 0, 0, 15]
            },
            document: {
                fontSize: 12,
                lineHeight: 1,
                margin: [16, 0, 0, 0]
            }
        },
        defaultStyle: {
            fontSize: 16,
            bold: false,
            lineHeight: 1.1
        }
    };
    makePdf(dd, j.jobNumber);
};

export default exportJobSheet;
