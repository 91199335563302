import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    detail: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

const Detail = ({ title, text }) => {
    const classes = useStyles();
    return (
        <div className={classes.detail}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography component="div" variant="body1">
                {text || "N/A"}
            </Typography>
        </div>
    );
};

export default Detail;
