import { makeStyles, Slider, Typography, withStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        padding: `0 ${theme.spacing(0.5)}px`,
        margin: `${theme.spacing(2)}px 0`
    },
    labels: {
        height: theme.spacing(2)
    },
    lowLabel: {
        float: "left",
        marginTop: -theme.spacing()
    },
    highLabel: {
        marginTop: -theme.spacing(),
        float: "right"
    }
}));

const StyledSlider = withStyles({
    root: {
        height: 8
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
        }
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)"
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 8,
        borderRadius: 4
    }
})(Slider);

const Label = ({ children, className }) => (
    <Typography className={className} variant="caption" gutterBottom>
        {children}
    </Typography>
);

const RiskSlider = ({ label, value, onChange }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Label>{label}</Label>
            <StyledSlider
                value={value || 0} // preview comes through as undefined
                onChange={(_, value) => onChange && onChange(value)}
                valueLabelDisplay="auto"
                max={5}
            />
            <div className={classes.labels}>
                <Label className={classes.lowLabel}>Low</Label>
                <Label className={classes.highLabel}>High</Label>
            </div>
        </div>
    );
};

export default RiskSlider;
