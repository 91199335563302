import { Email } from "@material-ui/icons";
import React from "react";
import InputField from "./InputField";

const EmailField = ({ autoFocus }) => (
    <InputField
        label="Email Address"
        name="email"
        autoFocus={autoFocus}
        Icon={Email}
    />
);

export default EmailField;
