import {
    Chip,
    CircularProgress,
    FormHelperText,
    makeStyles,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    dropdown: {
        margin: `${theme.spacing()}px 0`,
        padding: `${theme.spacing()}px 0`
    },
    helperText: {
        marginLeft: 14,
        marginRight: 14,
        color: theme.palette.error.main
    },
    autocomplete: {
        flex: 1
    },
    error: {
        "& fieldset": {
            borderColor: theme.palette.error.main
        }
    }
}));

const ensure = (prop, name) => {
    if (!prop) throw new Error(`${name} prop must be supplied.`);
};

const FormikDropdown = ({
    name,
    value,
    formik,
    options,
    label,
    multiple,
    disableCloseOnSelect,
    renderOption,
    getOptionLabel = option => option.name,
    getOptionSelected = (option, value) => value && option.id === value.id,
    className,
    disableClearable
}) => {
    ensure(formik, "Formik");
    const classes = useStyles();
    const errorText = formik.touched[name] && formik.errors[name];
    const actualValue = value || formik.values[name];

    // If we have loaded the options and there's a value but it's not in the options, pretend that it is!
    const actualOptions =
        !multiple && options && actualValue && !options.includes(actualValue)
            ? [...options, actualValue]
            : options;

    return (
        <div className={clsx(classes.dropdown, className)}>
            <Autocomplete
                className={clsx(
                    classes.autocomplete,
                    errorText && classes.error
                )}
                filterSelectedOptions={!disableCloseOnSelect}
                disableClearable={disableClearable}
                name={name}
                options={actualOptions || []}
                onChange={(_, value) => {
                    formik.handleChange({
                        target: {
                            name,
                            value
                        }
                    });
                }}
                onBlur={value => {
                    formik.handleBlur({
                        target: {
                            name,
                            value
                        }
                    });
                }}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            color="primary"
                            variant="outlined"
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                multiple={multiple}
                disableCloseOnSelect={disableCloseOnSelect}
                renderOption={renderOption}
                getOptionLabel={option => getOptionLabel(option) || ""}
                getOptionSelected={getOptionSelected}
                loading={!options}
                value={actualValue || (multiple ? [] : null)}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {!options ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
            {errorText && (
                <FormHelperText className={classes.helperText}>
                    {errorText}
                </FormHelperText>
            )}
        </div>
    );
};

export default FormikDropdown;
