import getFieldDefinition from "./fields";

const defaultInit = _ => ({ value: null });

const mapField = field => {
    const init = getFieldDefinition(field).init || defaultInit;
    return {
        ...field,
        ...init(field)
    };
};

const initForm = (jobSheetId, form) => ({
    jobSheetId,
    form: {
        id: form.id,
        name: form.name,
        section: form.section
    },
    fields: form.fields.map(mapField)
});

export { default as Form } from "./Form";
export { initForm };
