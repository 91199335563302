import React from "react";

const makeInput = (Component, getProps = _ => {}) => (
    { name, type, value, ...rest },
    index,
    formik,
    autoFocus,
    disabled
) => {
    return (
        <Component
            key={index}
            label={name}
            name={`fields[${index}].value`}
            onChange={formik.handleChange}
            value={formik.values.fields[index].value}
            index={index}
            autoFocus={autoFocus}
            disabled={disabled}
            {...rest}
            {...getProps(formik.values.fields[index])}
        />
    );
};

export default makeInput;
