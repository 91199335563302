import React from "react";
import { ConfirmDialog, Maybe } from "../../components";

const ConfirmDelete = ({ onConfirm, onCancel, noun }) => (
    <ConfirmDialog
        title={`Delete ${noun}`}
        confirm="Delete"
        onConfirm={onConfirm}
        onCancel={onCancel}
    >
        Do you want to delete this {noun}?
    </ConfirmDialog>
);

export default Maybe(ConfirmDelete);
