import { makeStyles } from "@material-ui/core";
import React from "react";
import { defaultStyles, FileIcon } from "react-file-icon";

const useStyles = makeStyles(_ => ({
    icon: {
        width: 40
    }
}));

const DocumentIcon = ({ extension }) => {
    const classes = useStyles();
    return (
        <div className={classes.icon}>
            <FileIcon extension={extension} {...defaultStyles[extension]} />
        </div>
    );
};

export default DocumentIcon;
