import { Button, Divider, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    actions: {
        margin: `${theme.spacing(2)}px ${theme.spacing()}px`,
        textAlign: "right",
        "& > :not(:first-child)": {
            marginLeft: theme.spacing()
        }
    },
    divider: {
        marginTop: theme.spacing(2)
    }
}));

const SaveCancel = ({ onCancel, onClose }) => {
    const classes = useStyles();
    if (onCancel && onClose) {
        throw new Error("SaveCancel cannot have both onCancel and onClose");
    }

    return (
        <>
            <Divider className={classes.divider} />
            <div className={classes.actions}>
                {onClose ? (
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                    >
                        Close
                    </Button>
                ) : (
                    <>
                        <Button color="primary" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

export default SaveCancel;
