import { makeStyles } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React from "react";
import { Info } from ".";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.light
    }
}));

const BigError = () => {
    const classes = useStyles();
    return (
        <Info
            Icon={Error}
            iconClassName={classes.error}
            title="Error"
            subtitle="An unexpected error has occurred. Please try again later."
        />
    );
};

export default BigError;
