import { IconButton } from "@material-ui/core";
import { Business, NotListedLocation, Room } from "@material-ui/icons";
import React from "react";
import { Grid, MapLink } from "../components";
import DepotEditor from "./DepotEditor";

const newItem = {
    name: "New depot",
    active: true,
    address: {}
};

const Depot = () => (
    <Grid
        api="depots"
        actions={[
            {
                getAction: row =>
                    row.placeId ? (
                        <MapLink address={row}>
                            <IconButton color="primary">
                                <Room />
                            </IconButton>
                        </MapLink>
                    ) : (
                        <IconButton disabled>
                            <NotListedLocation />
                        </IconButton>
                    )
            }
        ]}
        newItem={newItem}
        empty={{
            Icon: Business,
            title: "Add some depots",
            subtitle: "Create a depot and it will show up here."
        }}
        addConfig={{
            title: "Add Depot"
        }}
        editConfig={{
            title: "Edit Depot"
        }}
        removeConfig={{
            title: "Delete Depot?"
        }}
        searchConfig={{
            blank: "No Depots found"
        }}
        Editor={DepotEditor}
    />
);

export default Depot;
