import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import React from "react";
import { fromDateString, toDateString } from "../../../dates";
import Field from "./Field";

const DatePicker = ({
    name,
    autoFocus,
    label,
    value,
    onChange,
    index,
    disabled
}) => (
    <Field
        index={index}
        label={label}
        name={name}
        onChange={onChange}
        fullWidth
    >
        <MuiDatePicker
            autoFocus={autoFocus}
            id={name}
            name={name}
            format="PPP"
            inputVariant="outlined"
            onChange={value => {
                onChange({
                    target: {
                        name,
                        value: toDateString(value)
                    }
                });
            }}
            disabled={disabled}
            value={fromDateString(value)}
        />
    </Field>
);

export default DatePicker;
