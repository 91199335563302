import { Work } from "@material-ui/icons";
import React from "react";
import { Grid } from "../components";
import { basicColumns } from "../components/Grid/columns";
import sections from "../sections";
import WorkPackEditor from "./WorkPackEditor";

const newItem = {
    name: "New work pack",
    sections: sections.map(section => ({
        name: section,
        forms: [],
        documents: []
    }))
};

const WorkPacks = () => (
    <Grid
        api="workpacks"
        columns={basicColumns}
        newItem={newItem}
        empty={{
            Icon: Work,
            title: "Add some work packs",
            subtitle: "Create a work pack and it will show up here."
        }}
        addConfig={{
            title: "Add Work Pack"
        }}
        editConfig={{
            title: "Edit Work Pack"
        }}
        removeConfig={{
            title: "Delete Work Pack?"
        }}
        searchConfig={{
            blank: "No Work Packs found"
        }}
        Editor={WorkPackEditor}
    />
);

export default WorkPacks;
