import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, FullScreenDialog, Overlay, SaveCancel } from "../";
import { nullify } from "../../util";
import Maybe from "../Maybe";
import getFieldDefinition from "./fields";

const FormContents = ({ item, onSave, onCancel, preview, canChangeForms }) => {
    if (preview && onSave) {
        throw new Error("Cannot pass onSave to a preview form");
    }
    const canSave = !preview && canChangeForms;
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);
    const [message, setMessage] = useState();
    const [saving, setSaving] = useState(false);
    const formik = useFormik({
        initialValues: item,
        validateOnBlur: false,
        onSubmit: async form => {
            if (preview) {
                throw new Error("Cannot save a form preview");
            }
            setSaving(true);
            form.name = form.fields.find(f => f.value)?.value || form.form.name;
            const result = await onSave(form);
            if (!result) {
                setMessage(
                    `An error has occurred while trying to save the form`
                );
            }
            if (mounted.current) {
                setSaving(false);
            }
        }
    });

    const fieldDefinitions = item.fields.map(field => ({
        definition: getFieldDefinition(field),
        field
    }));
    const autoFocusIndex = fieldDefinitions.findIndex(
        ({ definition }) => definition.acceptsFocus
    );
    return (
        <>
            <ErrorMessage message={message} onClose={nullify(setMessage)} />
            <form onSubmit={formik.handleSubmit}>
                {fieldDefinitions.map(({ definition, field }, index) =>
                    definition.render(
                        field,
                        index,
                        formik,
                        index === autoFocusIndex,
                        !canChangeForms
                    )
                )}
                <SaveCancel
                    onCancel={canSave && onCancel}
                    onClose={!canSave && onCancel}
                />
            </form>
            <Overlay open={saving} />
        </>
    );
};

const FullScreenForm = ({
    item,
    onSave,
    onCancel,
    preview,
    canChangeForms
}) => (
    <FullScreenDialog open={true} onCancel={onCancel} title={item.form.name}>
        <FormContents
            item={item}
            onSave={onSave}
            onCancel={onCancel}
            preview={preview}
            canChangeForms={canChangeForms}
        />
    </FullScreenDialog>
);

export { FormContents };
export default Maybe(FullScreenForm);
