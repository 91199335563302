import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import {
    FormikDropdown,
    FormikMultiselect,
    FormikSwitch,
    FormikTextField,
    SaveCancel
} from "../components";
import roles from "../roles";
import useApi from "../useApi";
import { onlyActive } from "../util";

const validationSchema = {
    givenName: Yup.string().required("Given name is required"),
    familyName: Yup.string().required("Family name is required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    roles: Yup.array().of(Yup.string()).required("A role is required")
};

const minPasswordLength = process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH;

const passwordValidationSchema = {
    ...validationSchema,
    password: Yup.string()
        .required("Password is required")
        .min(
            minPasswordLength,
            `Password must be at least ${minPasswordLength} characters`
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords do not match")
        .required("Confirmation of password is required")
};

const UserEditor = ({ item, onSave, onCancel, savingError }) => {
    const formik = useFormik({
        initialValues: item,
        validationSchema: Yup.object(
            item.id ? validationSchema : passwordValidationSchema
        ),
        onSubmit: onSave
    });

    const { data: depots } = useApi("depots", true);

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikTextField
                formik={formik}
                name="email"
                label="Email"
                error={savingError === 409 ? "Email already exists" : null}
                autoFocus
            />
            <FormikTextField
                formik={formik}
                name="givenName"
                label="Given name"
            />
            <FormikTextField
                formik={formik}
                name="familyName"
                label="Family name"
            />
            {!item.id && (
                <>
                    <FormikTextField
                        formik={formik}
                        name="password"
                        label="Password"
                        type="password"
                    />
                    <FormikTextField
                        formik={formik}
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                    />
                </>
            )}
            <FormikSwitch formik={formik} name="active" label="Active" />
            <FormikDropdown
                name="depot"
                formik={formik}
                options={onlyActive(depots)}
                label="Depot"
            />
            <FormikMultiselect
                name="roles"
                label="Roles"
                formik={formik}
                options={roles}
            />
            <SaveCancel onCancel={onCancel} />
        </form>
    );
};

export default UserEditor;
