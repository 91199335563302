import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { FormikTextField, Overlay } from "../components";
import useApi from "../useApi";

const minPasswordLength = process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH;

const states = {
    INIT: "init",
    SAVING: "saving",
    ERROR: "error",
    WARNING: "warning",
    SUCCESS: "success"
};

const messages = {
    error: "An error has occurred while trying to save.",
    success:
        "Your password has been updated. Use it the next time you sign in.",
    warning: "Your current password is incorrect."
};

const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
        .required("New password is required")
        .notOneOf(
            [Yup.ref("currentPassword")],
            "New password cannot be the same as the current password"
        )
        .min(
            minPasswordLength,
            `Password must be at least ${minPasswordLength} characters`
        ),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords do not match")
        .required("Confirmation of new password is required")
});

const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    title: {
        paddingBottom: theme.spacing(2)
    },
    message: {
        marginBottom: theme.spacing(2)
    }
}));

const Account = () => {
    const classes = useStyles();
    const [state, setState] = useState(states.INIT);
    const { update, response } = useApi("auth");
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async data => {
            setState(states.SAVING);
            await update(data);
            if (response.ok) {
                setState(states.SUCCESS);
                formik.resetForm();
            } else if (response.status === 401) {
                setState(states.WARNING);
            } else {
                setState(states.ERROR);
            }
        }
    });

    return (
        <Paper className={classes.root}>
            <Typography className={classes.title} variant="h6">
                Change Password
            </Typography>
            {[states.ERROR, states.WARNING, states.SUCCESS].includes(state) && (
                <Alert className={classes.message} severity={state}>
                    {messages[state]}
                </Alert>
            )}
            <form onSubmit={formik.handleSubmit}>
                <FormikTextField
                    formik={formik}
                    name="currentPassword"
                    label="Current Password"
                    type="password"
                />
                <FormikTextField
                    formik={formik}
                    name="newPassword"
                    label="New Password"
                    type="password"
                />
                <FormikTextField
                    formik={formik}
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    type="password"
                />
                <Button type="submit" variant="contained" color="primary">
                    Save Password
                </Button>
            </form>
            <Overlay open={state === states.SAVING} />
        </Paper>
    );
};

export default Account;
