import { People } from "@material-ui/icons";
import React from "react";
import { Grid, User } from "../components";
import {
    activeColumn,
    makeColumn,
    modifiedColumn
} from "../components/Grid/columns";
import { SYSTEM_ADMINISTRATOR, USER } from "../roles";
import { getName } from "../util";
import UserEditor from "./UserEditor";

const newItem = {
    email: "",
    givenName: "",
    familyName: "",
    active: true,
    depot: null,
    roles: [USER],
    password: "",
    confirmPassword: ""
};

const Users = () => (
    <Grid
        api="users"
        newItem={newItem}
        getName={getName}
        columns={[
            { name: "Name", getValue: user => <User user={user} showName /> },
            makeColumn("Email"),
            modifiedColumn,
            activeColumn
        ]}
        empty={{
            Icon: People,
            title: "Add some users",
            subtitle: "Create a user and they will show up here."
        }}
        addConfig={{
            title: "Add User"
        }}
        editConfig={{
            title: "Edit User"
        }}
        removeConfig={{
            title: "Delete User?",
            canDelete: ({ roles }) =>
                !roles.some(role => role === SYSTEM_ADMINISTRATOR)
        }}
        searchConfig={{
            blank: "No Users found"
        }}
        Editor={UserEditor}
    />
);

export default Users;
