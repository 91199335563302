import { makeStyles } from "@material-ui/core";
import React from "react";
import { User } from "../../components";
import Detail from "./Detail";

const useStyles = makeStyles(theme => ({
    user: {
        marginBottom: theme.spacing(0.25)
    }
}));

const UserList = ({ title, users }) => {
    const classes = useStyles();
    return (
        <Detail
            title={title}
            text={
                // text needs to be null for default to show, so if there are no users, return null
                users.length &&
                users.map(user => (
                    <User
                        className={classes.user}
                        key={user.id}
                        user={user}
                        showName
                    />
                ))
            }
        />
    );
};

export default UserList;
