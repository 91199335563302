import { Badge } from "@material-ui/core";
import { amber, green, red } from "@material-ui/core/colors";
import { AssignmentLate, AssignmentTurnedIn } from "@material-ui/icons";
import React from "react";

const fontSize = 50;

const RiskIcon = ({ rating }) =>
    rating >= 15 ? (
        <AssignmentLate style={{ fontSize, color: red[500] }} />
    ) : rating <= 6 ? (
        <AssignmentTurnedIn style={{ fontSize, color: green[500] }} />
    ) : (
        <AssignmentLate style={{ fontSize, color: amber[500] }} />
    );

const RiskRating = ({ likelihood, severity }) => {
    const rating = (likelihood || 0) * (severity || 0);
    return (
        <Badge color="secondary" badgeContent={rating} showZero>
            <RiskIcon rating={rating} />
        </Badge>
    );
};

export default RiskRating;
