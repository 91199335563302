import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
    field: {
        margin: `${theme.spacing()}px 0`
    }
}));

const FormikSwitch = ({ formik, name, label }) => {
    const classes = useStyles();
    return (
        <FormControlLabel
            className={classes.field}
            control={
                <Switch
                    checked={formik.values.active}
                    onChange={formik.handleChange}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    );
};
export default FormikSwitch;
