import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    TextField
} from "@material-ui/core";
import { List } from "@material-ui/icons";
import React, { useState } from "react";
import Choice from "../inputs/Choice";
import makeEditor from "../makeEditor";
import makeInput from "../makeInput";

const useStyles = makeStyles({
    dialog: {
        width: "80%"
    }
});

const OptionsDialog = ({ field, index, onClose, onOk }) => {
    const classes = useStyles();
    const [options, setOptions] = useState(
        field.options ? field.options.join("\n") : ""
    );
    return (
        <Dialog maxWidth="xs" classes={{ paper: classes.dialog }} open={true}>
            <DialogTitle>{field.name || "Options"}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Options"
                    rows="8"
                    multiline
                    onChange={e => {
                        setOptions(e.target.value);
                    }}
                    value={options}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        onOk(index, options.split("\n").filter(String))
                    }
                    color="primary"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ChoiceEditor = ({ formik, field, index }) => {
    const [editingOptions, setEditingOptions] = useState();

    const handleSetOptions = (index, options, formik) => {
        formik.handleChange({
            target: { name: `fields[${index}].options`, value: options }
        });
    };

    return (
        <>
            {editingOptions && (
                <OptionsDialog
                    {...editingOptions}
                    onOk={(index, options) => {
                        handleSetOptions(index, options, formik);
                        setEditingOptions(false);
                    }}
                    onClose={() => setEditingOptions(false)}
                />
            )}
            <IconButton
                onClick={() =>
                    setEditingOptions({
                        field,
                        index
                    })
                }
                aria-label="edit options"
            >
                <List />
            </IconButton>
        </>
    );
};

const choice = {
    name: "Choice",
    type: "choice",
    icon: <List />,
    acceptsFocus: true,
    editor: params =>
        makeEditor(
            "Choice label",
            <ChoiceEditor
                formik={params.formik}
                field={params.field}
                index={params.index}
            />,
            ["name", "options"]
        )(params),
    render: makeInput(Choice)
};

export default choice;
