import { Button, makeStyles } from "@material-ui/core";
import { Assignment, Visibility } from "@material-ui/icons";
import { FieldArray, Form as FormikForm, Formik } from "formik";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Yup from "yup";
import {
    ButtonMenu,
    FormikDropdown,
    FormikTextField,
    Info,
    SaveCancel
} from "../components";
import { Form, initForm } from "../components/Form";
import getFieldDefinition, { fields } from "../components/Form/fields";
import sections from "../sections";

const useStyles = makeStyles(theme => ({
    form: {
        minWidth: 250
    },
    sectionDropdown: {
        marginTop: 4,
        marginBottom: 24,
        padding: 0
    },
    addField: {
        marginRight: theme.spacing(),
        marginBottom: theme.spacing(3),
        textAlign: "right"
    },
    dragArea: {
        marginLeft: -24
    },
    preview: {
        float: "left"
    }
}));

const renderField = props => {
    const fd = getFieldDefinition(props.field);
    return fd.editor(props);
};

const DragDropFields = ({ onDragEnd, formik, classes, arrayHelpers }) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {provided => (
                <div
                    className={classes.dragArea}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {formik.values.fields.map((field, index) => (
                        <Draggable
                            key={index}
                            draggableId={`field-${index}`}
                            index={index}
                        >
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {renderField({
                                        formik,
                                        arrayHelpers,
                                        field,
                                        index
                                    })}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
);

const FormDesigner = ({ item, onSave, onCancel }) => {
    const classes = useStyles();
    const [previewing, setPreviewing] = useState();

    const handleDragEnd = arrayHelpers => result => {
        if (result.destination) {
            arrayHelpers.move(result.source.index, result.destination.index);
        }
    };

    return (
        <>
            <Form
                item={previewing}
                onCancel={() => setPreviewing(null)}
                preview
                canChangeForms
            />
            <Formik
                initialValues={item}
                validationSchema={Yup.object({
                    name: Yup.string().required("Form name is required"),
                    fields: Yup.array()
                        .of(
                            Yup.object().shape({
                                name: Yup.string().required(
                                    "Field name is required"
                                ),
                                type: Yup.string(),
                                options: Yup.array().when("type", {
                                    is: "choice",
                                    then: Yup.array()
                                        .of(Yup.string())
                                        .required("Some options are required")
                                }),
                                controlMeasures: Yup.array().when("type", {
                                    is: "risk",
                                    then: Yup.array()
                                        .of(Yup.string())
                                        .required(
                                            "Some control measures are required"
                                        )
                                }),
                                likelihood: Yup.number().when("type", {
                                    is: "risk",
                                    then: Yup.number().min(
                                        1,
                                        "Likelihood is required"
                                    )
                                }),
                                severity: Yup.number().when("type", {
                                    is: "risk",
                                    then: Yup.number().min(
                                        1,
                                        "Severity is required"
                                    )
                                })
                            })
                        )
                        .required("Some form fields are required.")
                })}
                onSubmit={onSave}
            >
                {formik => (
                    <FormikForm className={classes.form}>
                        <FormikTextField
                            formik={formik}
                            name="name"
                            label="Name"
                            placeholder="Enter a name for this form"
                            autoFocus
                        />
                        <FormikDropdown
                            name="section"
                            label="Section"
                            formik={formik}
                            className={classes.sectionDropdown}
                            options={sections}
                            getOptionSelected={(option, value) =>
                                value === option
                            }
                            getOptionLabel={option => option}
                            disableClearable
                        />
                        <FieldArray
                            name="fields"
                            render={arrayHelpers => (
                                <>
                                    <DragDropFields
                                        onDragEnd={handleDragEnd(arrayHelpers)}
                                        formik={formik}
                                        arrayHelpers={arrayHelpers}
                                        classes={classes}
                                    />
                                    {formik.values.fields.length === 0 && (
                                        <Info
                                            Icon={Assignment}
                                            title="Add some fields"
                                            subtitle={
                                                (formik.touched.fields &&
                                                    formik.errors.fields) ||
                                                "Create your form by adding some fields."
                                            }
                                            error={
                                                formik.touched.fields &&
                                                formik.errors.fields
                                            }
                                        />
                                    )}
                                    <div>
                                        <Button
                                            className={classes.preview}
                                            startIcon={<Visibility />}
                                            onClick={() =>
                                                setPreviewing(
                                                    initForm(
                                                        formik.values.id,
                                                        formik.values
                                                    )
                                                )
                                            }
                                            color="primary"
                                        >
                                            Preview
                                        </Button>
                                        <ButtonMenu
                                            className={classes.addField}
                                            options={fields
                                                .filter(
                                                    f =>
                                                        !f.specificToSection ||
                                                        f.specificToSection ===
                                                            formik.values
                                                                .section
                                                )
                                                .map(field => ({
                                                    name: field.name,
                                                    icon: field.icon,
                                                    onClick: () => {
                                                        const fd = getFieldDefinition(
                                                            field
                                                        );
                                                        const fields = fd.fieldsToAdd || [
                                                            field.type
                                                        ];
                                                        fields.forEach(type => {
                                                            arrayHelpers.push({
                                                                name: "",
                                                                type
                                                            });
                                                        });
                                                    }
                                                }))}
                                        >
                                            Add Field
                                        </ButtonMenu>
                                    </div>
                                </>
                            )}
                        />
                        <SaveCancel onCancel={onCancel} />
                    </FormikForm>
                )}
            </Formik>
        </>
    );
};

export default FormDesigner;
