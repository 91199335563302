import { Check, Clear } from "@material-ui/icons";
import React from "react";
import { DisplayDate, User } from "..";

const makeColumn = (name, value, hideOn = "xs") => ({
    name,
    hideOn,
    getValue: row => row[value || name.toLowerCase()]
});

const nameColumn = makeColumn("Name", null, null);

const modifiedByColumn = {
    name: "Modified by",
    hideOn: "xs",
    getValue: ({ modifiedBy }) => modifiedBy && <User user={modifiedBy} />
};

const modifiedColumn = {
    name: "Modified",
    hideOn: "xs",
    getValue: ({ modified }) => modified && <DisplayDate date={modified} />
};

const activeColumn = {
    name: "Active",
    hideOn: ["xs", "sm"],
    getValue: ({ active }) =>
        active ? <Check color="action" /> : <Clear color="action" />
};

const basicColumns = [nameColumn, modifiedColumn, modifiedByColumn];
const defaultColumns = [...basicColumns, activeColumn];

export {
    makeColumn,
    nameColumn,
    modifiedColumn,
    modifiedByColumn,
    activeColumn,
    basicColumns,
    defaultColumns
};
export default defaultColumns;
