import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    TextField
} from "@material-ui/core";
import { Edit, Warning } from "@material-ui/icons";
import React, { useState } from "react";
import Risk from "../inputs/Risk";
import makeEditor from "../makeEditor";
import makeInput from "../makeInput";
import RiskScore from "../RiskScore";

const useStyles = makeStyles({
    dialog: {
        width: "80%"
    }
});

const RiskDialog = ({ field, index, onClose, onOk }) => {
    const classes = useStyles();
    const [controlMeasures, setControlMeasures] = useState(
        field.controlMeasures?.join("\n") || ""
    );
    const [likelihood, setLikelihood] = useState(field.likelihood || 0);
    const [severity, setSeverity] = useState(field.severity || 0);

    return (
        <Dialog maxWidth="xs" classes={{ paper: classes.dialog }} open={true}>
            <DialogTitle>{field.name || "Risk"}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    name="controlMeasures"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Control Measures"
                    rows="6"
                    multiline
                    onChange={e => {
                        setControlMeasures(e.target.value);
                    }}
                    value={controlMeasures}
                />
                <RiskScore
                    likelihood={likelihood}
                    onLikelihoodChange={setLikelihood}
                    severity={severity}
                    onSeverityChange={setSeverity}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        onOk(index, {
                            ...field,
                            likelihood,
                            severity,
                            controlMeasures: controlMeasures
                                .split("\n")
                                .filter(String)
                        })
                    }
                    color="primary"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const RiskEditor = ({ formik, field, index }) => {
    const [editing, setEditing] = useState();

    const handleChange = (index, field, formik) => {
        formik.handleChange({
            target: { name: `fields[${index}]`, value: field }
        });
    };

    return (
        <>
            {editing && (
                <RiskDialog
                    {...editing}
                    onOk={(index, field) => {
                        handleChange(index, field, formik);
                        setEditing(null);
                    }}
                    onClose={() => setEditing(null)}
                />
            )}
            <IconButton
                onClick={() =>
                    setEditing({
                        field,
                        index
                    })
                }
                aria-label="edit risk"
            >
                <Edit />
            </IconButton>
        </>
    );
};

const init = field => ({
    controlMeasures: field.controlMeasures?.map(name => ({
        name,
        value: false
    }))
});

const risk = {
    name: "Risk",
    type: "risk",
    icon: <Warning />,
    acceptsFocus: true,
    init,
    editor: params =>
        makeEditor(
            "Risk name",
            <RiskEditor
                formik={params.formik}
                field={params.field}
                index={params.index}
            />,
            ["name", "controlMeasures", "likelihood", "severity"]
        )(params),
    render: makeInput(Risk, field => ({
        controlMeasures: field.controlMeasures
    })),
    specificToSection: "Job Specific Risk Assessment & Method Statements (RAMS)"
};

export default risk;
