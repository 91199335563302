import { OutlinedInput } from "@material-ui/core";
import React from "react";
import Field from "../inputs/Field";

const TextInput = ({
    autoFocus,
    index,
    label,
    name,
    value,
    onChange,
    rows,
    disabled
}) => (
    <Field
        index={index}
        label={label}
        name={name}
        onChange={onChange}
        fullWidth
    >
        <OutlinedInput
            autoFocus={autoFocus}
            disabled={disabled}
            id={name}
            name={name}
            value={value}
            rows={rows}
            multiline={rows > 1 ? true : false}
        />
    </Field>
);

export default TextInput;
