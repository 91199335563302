import { Hidden, makeStyles, Typography } from "@material-ui/core";
import { CloudDownload, Folder } from "@material-ui/icons";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import React, { useState } from "react";
import { Action, DocumentIcon, ErrorMessage, Grid } from "../components";
import { modifiedByColumn, modifiedColumn } from "../components/Grid/columns";
import useDownload from "../useDownload";
import useUser from "../useUser";
import { displaySize, nullify } from "../util";
import DocumentEditor from "./DocumentEditor";

const useStyles = makeStyles(theme => ({
    inline: {
        display: "flex",
        alignItems: "center"
    },
    name: {
        marginLeft: theme.spacing(1)
    },
    icon: {
        flexShrink: 0
    }
}));

const Library = () => {
    const classes = useStyles();
    const { isAdmin } = useUser();
    const [message, setMessage] = useState();
    const download = useDownload(setMessage);

    const nameColumn = {
        name: "Name",
        getValue: ({ name, size, extension }) => (
            <div className={classes.inline}>
                <Hidden smDown>
                    <div className={classes.icon}>
                        <DocumentIcon extension={extension} />
                    </div>
                </Hidden>
                <div className={classes.name}>
                    <Typography variant="subtitle1">{name}</Typography>
                    <Hidden xsDown>
                        <Typography color="textSecondary" variant="caption">
                            {displaySize(size)}
                        </Typography>
                    </Hidden>
                </div>
            </div>
        )
    };

    return (
        <>
            <ErrorMessage message={message} onClose={nullify(setMessage)} />
            <Grid
                api="library"
                columns={[nameColumn, modifiedColumn, modifiedByColumn]}
                actions={[
                    {
                        getAction: row => (
                            <Action
                                onClick={download(row)}
                                Icon={CloudDownload}
                                name="Download"
                            />
                        )
                    }
                ]}
                newItem={{}}
                empty={{
                    Icon: Folder,
                    title: isAdmin
                        ? "Add some documents"
                        : "No documents available",
                    subtitle: isAdmin
                        ? "Upload a document and it will show up here."
                        : "When a document is uploaded it will show up here."
                }}
                addConfig={{
                    title: "Add Documents"
                }}
                removeConfig={{
                    title: "Delete Document?"
                }}
                searchConfig={{
                    blank: "No Documents found"
                }}
                Editor={DocumentEditor}
                fullEditor
            />
        </>
    );
};

export default Library;
