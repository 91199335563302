import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import useApi from "../../useApi";
import useUser from "../../useUser";
import CredentialsForm from "./CredentialsForm";
import PasswordField from "./PasswordField";

const minPasswordLength = process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH;

const validationSchema = Yup.object({
    newPassword: Yup.string()
        .required("New password is required")
        .min(
            minPasswordLength,
            `Password must be at least ${minPasswordLength} characters`
        ),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords do not match")
        .required("Confirmation of new password is required")
});

const initialValues = {
    newPassword: "",
    confirmNewPassword: ""
};

const ResetPassword = () => {
    const [error, setError] = useState();
    const { response, post } = useApi("auth/resetpassword");
    const { signIn } = useUser();
    const { code } = useParams();

    const handleReset = async ({ newPassword, confirmNewPassword }) => {
        setError();
        var token = await post({ code, newPassword, confirmNewPassword });
        if (response.ok) {
            signIn({ ...token }, true);
        } else {
            setError("Unable to reset your password. Try again later.");
        }
    };

    return (
        <CredentialsForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleReset}
            error={error}
            buttonText="Reset password"
            link={{
                to: "/",
                label: "Sign in"
            }}
        >
            <Typography variant="h6" gutterBottom>
                Password reset
            </Typography>
            <Typography variant="body1" gutterBottom>
                Enter and confirm your new password.
            </Typography>
            <PasswordField name="newPassword" label="New Password" autoFocus />
            <PasswordField
                name="confirmNewPassword"
                label="Confirm New Password"
            />
        </CredentialsForm>
    );
};

export default ResetPassword;
