import { IconButton } from "@material-ui/core";
import { AccountBalance, NotListedLocation, Room } from "@material-ui/icons";
import React from "react";
import { Grid, MapLink } from "../components";
import CustomerEditor from "./CustomerEditor";

const newItem = {
    name: "New customer",
    active: true,
    invoiceAddress: {}
};

const Customers = () => (
    <Grid
        api="customers"
        actions={[
            {
                getAction: row =>
                    row.placeId ? (
                        <MapLink address={row}>
                            <IconButton color="primary">
                                <Room />
                            </IconButton>
                        </MapLink>
                    ) : (
                        <IconButton disabled>
                            <NotListedLocation />
                        </IconButton>
                    )
            }
        ]}
        newItem={newItem}
        empty={{
            Icon: AccountBalance,
            title: "Add some customers",
            subtitle: "Create a customer and it will show up here."
        }}
        addConfig={{
            title: "Add Customer"
        }}
        editConfig={{
            title: "Edit Customer"
        }}
        removeConfig={{
            title: "Delete Customer?"
        }}
        searchConfig={{
            blank: "No Customers found"
        }}
        Editor={CustomerEditor}
    />
);

export default Customers;
