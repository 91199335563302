import { Hidden, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";

const useStyles = makeStyles(theme => ({
    time: {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing() / 2,
        whiteSpace: "nowrap"
    },
    date: {
        whiteSpace: "nowrap"
    }
}));

const DisplayDate = ({ date, hideTime, showTime }) => {
    const classes = useStyles();
    if (hideTime && showTime) {
        throw new Error(
            "Only one of hideTime or showTime can be set. By default this component is responsive and shows the time in a medium or higher screen. Setting showTime will always show the time. Setting hideTime will never show the time."
        );
    }
    return (
        <>
            <span className={classes.date}>{format(new Date(date), "PP")}</span>
            {!hideTime && (
                <Hidden smDown={!showTime}>
                    <span className={classes.time}>
                        {format(new Date(date), "p")}
                    </span>
                </Hidden>
            )}
        </>
    );
};

export default DisplayDate;
