import { Button, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useState } from "react";

const ButtonMenu = ({ className, options, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => setAnchorEl(null);

    const handleClick = action => () => {
        action();
        handleClose();
    };

    return (
        <div className={className}>
            <Button
                aria-controls="button-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                endIcon={<ArrowDropDown />}
                onClick={event => setAnchorEl(event.currentTarget)}
            >
                {children}
            </Button>
            <Menu
                id="button-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map(({ name, icon, onClick }) => (
                    <MenuItem key={name} onClick={handleClick(onClick)}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ButtonMenu;
