import { Divider, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import {
    FormikAddressPicker,
    FormikDatePicker,
    FormikDropdown,
    FormikTextField,
    SaveCancel,
    User
} from "../components";
import { USER } from "../roles";
import useApi from "../useApi";
import { getName, idName, onlyActive } from "../util";

const useStyles = makeStyles(theme => ({
    divider: {
        margin: `${theme.spacing(2)}px 0`
    },
    contact: {
        marginBottom: theme.spacing(2)
    }
}));

const AddressSection = ({ classes, label, name, formik }) => (
    <>
        <Divider className={classes.divider} />
        <Typography variant="subtitle2" gutterBottom>
            {label}
        </Typography>
        <FormikAddressPicker formik={formik} name={name} />
    </>
);

const renderUser = user => <User user={user} showName />;

const required = message =>
    Yup.object()
        .shape({
            id: Yup.string(),
            name: Yup.string()
        })
        .required(message)
        .nullable();

const JobSheetEditor = ({ item, onSave, onCancel }) => {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: item,
        validationSchema: Yup.object({
            jobNumber: Yup.string().required("Job number is required"),
            startDate: Yup.date().required("Start date is required"),
            finishDate: Yup.date().when("startDate", (startDate, schema) =>
                schema.min(
                    startDate,
                    "Finish date cannot be earlier than the start date"
                )
            ),
            depot: required("Depot is required"),
            workPack: required("Work pack is required"),
            customer: required("Customer is required")
        }),
        onSubmit: onSave
    });

    const { data: depots } = useApi("depots", true);
    const { data: customers } = useApi("customers", true);
    const { data: users } = useApi(`users/by?role=${USER}`, true);
    const { data: workpacks } = useApi("workpacks", true);

    const teamMembers = users?.filter(
        ({ id }) => formik.values.teamLeader?.id !== id
    );

    const teamLeaders = users?.filter(
        ({ id }) => !formik.values.teamMembers?.map(({ id }) => id).includes(id)
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikTextField
                formik={formik}
                name="jobNumber"
                label="Job number"
                autoFocus
            />
            <FormikDatePicker
                name="startDate"
                label="Start Date"
                formik={formik}
            />
            <FormikDatePicker
                name="finishDate"
                label="Finish Date"
                formik={formik}
            />
            <FormikDropdown
                name="depot"
                options={onlyActive(depots)}
                formik={formik}
                label="Depot"
            />
            <FormikDropdown
                name="teamLeader"
                renderOption={renderUser}
                getOptionLabel={getName}
                formik={formik}
                options={teamLeaders}
                label="Team Leader"
            />
            <FormikDropdown
                name="teamMembers"
                formik={formik}
                renderOption={renderUser}
                getOptionLabel={getName}
                options={teamMembers}
                label="Team Members"
                multiple
            />
            <FormikDropdown
                name="workPack"
                options={idName(workpacks)}
                formik={formik}
                label="Work Pack"
            />
            <FormikTextField
                formik={formik}
                name="scope"
                label="Scope"
                rows={4}
                hideSpace
            />
            <FormikDropdown
                name="customer"
                options={onlyActive(customers)}
                formik={formik}
                label="Customer"
            />
            <FormikTextField
                formik={formik}
                name="contactName"
                label="Contact Name"
                className={classes.contact}
                hideSpace
            />
            <FormikTextField
                formik={formik}
                name="contactPhoneNumber"
                label="Contact Phone Number"
                hideSpace
            />
            <AddressSection
                label="Site address"
                classes={classes}
                formik={formik}
                name="siteAddress"
            />
            <AddressSection
                label="Nearest hospital"
                classes={classes}
                formik={formik}
                name="nearestHospital"
            />
            <SaveCancel onCancel={onCancel} />
        </form>
    );
};

export default JobSheetEditor;
