import { makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormikTextField, SaveCancel } from "../components";
import FormikDropdown from "../components/FormikDropdown";
import useApi from "../useApi";
import { idName } from "../util";

const useStyles = makeStyles(theme => ({
    section: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    }
}));

const byName = (a, b) => -b.name.localeCompare(a.name);

const toDictionary = (property, array) =>
    array.reduce(
        (dict, element) => ({
            ...dict,
            [element[property]]: dict[element[property]]
                ? [...dict[element[property]], element]
                : [element]
        }),
        {}
    );

const WorkPackEditor = ({ item, onCancel, onSave }) => {
    const classes = useStyles();

    const formik = useFormik({
        initialValues: item,
        validationSchema: Yup.object({
            name: Yup.string().required("Work pack name is required")
        }),
        onSubmit: onSave
    });

    const { data: documents } = useApi("library", true);
    const { data: _forms } = useApi("forms", true);
    const forms = _forms ? toDictionary("section", _forms) : null;

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikTextField
                formik={formik}
                name="name"
                label="Name"
                autoFocus
            />
            {item.sections.map((section, index) => (
                <div key={section.name} className={classes.section}>
                    <Typography variant="h6">{section.name}</Typography>
                    <FormikDropdown
                        formik={formik}
                        label="Forms"
                        name={`sections[${index}].forms`}
                        value={formik.values.sections[index].forms}
                        multiple
                        options={
                            forms
                                ? forms[section.name]
                                    ? idName(forms[section.name]).sort(byName)
                                    : []
                                : null
                        }
                    />
                    <FormikDropdown
                        formik={formik}
                        label="Documents"
                        name={`sections[${index}].documents`}
                        value={formik.values.sections[index].documents}
                        multiple
                        options={documents
                            ?.map(({ id, name, size, extension }) => ({
                                id,
                                name,
                                size,
                                extension
                            }))
                            ?.sort(byName)}
                    />
                </div>
            ))}
            <SaveCancel onCancel={onCancel} />
        </form>
    );
};

export default WorkPackEditor;
