const makeReducer = reducerMap => (state, action) => {
    const func = reducerMap[action.type];
    if (!func)
        throw new Error(
            `Action ${action.type} does not exist as a function on the reducer.`
        );
    return func(state, action);
};

const getName = ({ givenName, familyName }, fallback = "") =>
    givenName && familyName ? `${givenName} ${familyName}` : fallback;

const getInitials = ({ givenName, familyName }) =>
    givenName && familyName ? `${givenName[0]}${familyName[0]}` : "";

const idName = list => list?.map(({ id, name }) => ({ id, name }));

const onlyActive = list => idName(list?.filter(l => l.active));

const isEmpty = obj => Object.keys(obj).length;

const displaySize = bytes => {
    if (!bytes) return "Unknown size";
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const arrayFrom = item => (item ? [item] : []);

const nullify = setter => () => setter(null);

export {
    makeReducer,
    getName,
    getInitials,
    idName,
    onlyActive,
    isEmpty,
    displaySize,
    arrayFrom,
    nullify
};
