import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { Field } from "formik";
import React from "react";

const useStyles = makeStyles(theme => ({
    input: {
        "& .Mui-focused svg": {
            color: theme.palette.primary.main
        }
    }
}));

const InputField = ({ name, label, autoFocus, Icon, type }) => {
    const classes = useStyles();
    return (
        <Field name={name}>
            {({ field, _, meta }) => {
                const errorText = meta.touched && meta.error;
                return (
                    <TextField
                        variant="outlined"
                        classes={{
                            root: classes.input
                        }}
                        margin="normal"
                        fullWidth
                        type={type}
                        label={label}
                        autoComplete={name}
                        autoFocus={autoFocus}
                        error={!!errorText}
                        helperText={errorText}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="action" />
                                </InputAdornment>
                            )
                        }}
                        {...field}
                    />
                );
            }}
        </Field>
    );
};

export default InputField;
