import { makeStyles, TextField } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    field: {
        margin: `${theme.spacing(0.5)}px 0`
    }
}));

const FormikTextField = ({
    formik,
    name,
    label,
    autoFocus,
    error,
    type,
    rows,
    className,
    value,
    hideSpace,
    placeholder
}) => {
    const classes = useStyles();
    const inputLabelProps = placeholder
        ? {
              shrink: true
          }
        : null;
    return (
        <TextField
            name={name}
            placeholder={placeholder}
            InputLabelProps={inputLabelProps}
            className={clsx(classes.field, className)}
            value={value || formik.values[name] || ""}
            fullWidth
            variant="outlined"
            label={label}
            autoFocus={autoFocus}
            onBlur={formik.handleBlur}
            error={(formik.touched[name] && !!formik.errors[name]) || !!error}
            helperText={
                (formik.touched[name] && formik.errors[name]) ||
                error ||
                (hideSpace ? null : " ")
            }
            onChange={formik.handleChange}
            type={type}
            multiline={rows > 1 ? true : false}
            rows={rows}
        />
    );
};
export default FormikTextField;
