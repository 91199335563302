import { useCallback } from "react";
import { useLocation } from "react-router-dom";

const getNewQueryString = q => (q ? `?q=${q}` : undefined);

const useSearch = () => {
    const location = useLocation();
    const q = new URLSearchParams(location.search).get("q");
    const getQueryString = useCallback(() => getNewQueryString(q), [q]);
    return {
        q,
        getNewQueryString,
        getQueryString
    };
};

export default useSearch;
