import React from "react";
import { ConfirmDialog, Maybe } from "../../components";

const ConfirmSignOff = ({ onConfirm, onCancel, item }) => (
    <ConfirmDialog
        title={`Sign off ${item.jobNumber}`}
        confirm="Sign off"
        onConfirm={onConfirm}
        onCancel={onCancel}
    >
        Do you want to sign off job <strong>{item.jobNumber}</strong>?
    </ConfirmDialog>
);

export default Maybe(ConfirmSignOff);
