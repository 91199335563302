import {
    Button,
    Container,
    Link,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Form, Formik } from "formik";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    "@global": {
        "html, body": {
            height: "100%",
            minWidth: 300
        },
        body: {
            backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 25%, ${theme.palette.background.default} 25%)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
        }
    },
    container: {
        paddingTop: theme.spacing(20),
        [theme.breakpoints.down("lg")]: {
            paddingTop: theme.spacing(18)
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(15)
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(12)
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(10)
        }
    },
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 280
    },
    form: {
        textAlign: "center",
        width: "100%" // Fix IE 11 issue.
    },
    submit: {
        margin: theme.spacing(2, 0)
    },
    logo: {
        width: 320,
        margin: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            width: 260,
            margin: theme.spacing(2)
        }
    },
    link: {
        textAlign: "center"
    },
    alert: {
        marginTop: theme.spacing()
    },
    poweredBy: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing()
    },
    poweredByLogo: {
        width: 64,
        height: "auto",
        margin: theme.spacing(0.5)
    }
}));

const CredentialsForm = ({
    onSubmit,
    initialValues,
    validationSchema,
    error,
    children,
    link,
    buttonText
}) => {
    const classes = useStyles();

    return (
        <Container className={classes.container} component="main" maxWidth="sm">
            <Paper className={classes.paper}>
                <img
                    className={classes.logo}
                    alt="Brand logo"
                    src={`${process.env.PUBLIC_URL}/api/branding/logo.png`}
                />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                >
                    {({ isSubmitting }) => (
                        <Form className={classes.form}>
                            {children}
                            {!!error && (
                                <Alert
                                    className={classes.alert}
                                    severity="error"
                                >
                                    {error}
                                </Alert>
                            )}
                            {buttonText ? (
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {buttonText}
                                </Button>
                            ) : (
                                <div className={classes.submit} />
                            )}
                        </Form>
                    )}
                </Formik>
                {link && (
                    <div className={classes.link}>
                        <Link
                            component={RouterLink}
                            variant="body2"
                            to={link.to}
                        >
                            {link.label}
                        </Link>
                    </div>
                )}
                <div className={classes.poweredBy}>
                    <Typography variant="caption">Powered by</Typography>
                    <img
                        className={classes.poweredByLogo}
                        alt="Powered by Riskd logo"
                        src={`${process.env.PUBLIC_URL}/logo.png`}
                    />
                </div>
            </Paper>
        </Container>
    );
};

export default CredentialsForm;
