import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    ReactPlugin,
    withAITracking
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import React, { useRef, useState } from "react";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import useFetch from "use-http";
import Layout from "./components/Layout";
import { Account, Welcome } from "./pages";
import { ForgotPassword, ResetPassword, SignIn } from "./pages/SignIn";
import { SYSTEM_ADMINISTRATOR } from "./roles";
import useFeatures from "./useFeatures";
import UserContext from "./UserContext";

const Routes = () => {
    const features = useFeatures();
    return (
        <Switch>
            {features.map(({ url, render }) => (
                <Route key={url} path={url}>
                    {render()}
                </Route>
            ))}
            <Route path="/account" component={Account} />
            <Route component={Welcome} />
        </Switch>
    );
};

const SignInRoutes = () => {
    return (
        <Switch>
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset/:code" component={ResetPassword} />
            <Route component={SignIn} />
        </Switch>
    );
};

const userKey = "riskd-auth-user";
const accessKey = "riskd-auth-access";
const refreshKey = "riskd-auth-refresh";
const expiryKey = "riskd-auth-expiry";

const App = () => {
    const initial = JSON.parse(localStorage.getItem(userKey));
    const { post: refresh, del } = useFetch("/api/auth/refresh", options => {
        options.headers["Content-Type"] = "application/json";
        options.cachePolicy = "no-cache";
        return options;
    });
    const [user, setUser] = useState(initial);
    const isAdmin = user?.roles?.includes(SYSTEM_ADMINISTRATOR);
    const history = useHistory();

    const EXPIRY_THRESHOLD = 30; // seconds before expiry to refresh
    const _getAccessToken = () => localStorage.getItem(accessKey);
    const getAccessToken = async () => {
        const expiry = localStorage.getItem(expiryKey);
        if (expiry && expiry - EXPIRY_THRESHOLD < Date.now() / 1000) {
            await refreshAccessToken();
        }
        return _getAccessToken();
    };

    const getRefreshToken = () => localStorage.getItem(refreshKey);

    const getRefreshCredentials = () => ({
        accessToken: _getAccessToken(),
        refreshToken: getRefreshToken()
    });

    const signOut = async () => {
        const token = getRefreshCredentials();
        localStorage.clear();
        setUser(null);
        await del(token);
    };
    const signIn = (
        {
            givenName,
            familyName,
            email,
            roles,
            accessToken,
            refreshToken,
            expiry
        },
        home
    ) => {
        const u = { givenName, familyName, email, roles };
        localStorage.setItem(userKey, JSON.stringify(u));
        localStorage.setItem(accessKey, accessToken);
        localStorage.setItem(refreshKey, refreshToken);
        localStorage.setItem(expiryKey, expiry);
        setUser(u);
        if (home) {
            history.replace("/");
        }
    };
    const refreshPromise = useRef(null);

    const refreshAccessToken = () => {
        if (!refreshPromise.current) {
            refreshPromise.current = refresh(getRefreshCredentials())
                .then(response => {
                    if (response.accessToken) {
                        const expiry = JSON.parse(
                            atob(response.accessToken.split(".")[1])
                        ).exp;
                        signIn({ ...user, ...response, expiry });
                    } else if (response.status === 401) {
                        signOut();
                    }
                    return response;
                })
                .finally(() => {
                    refreshPromise.current = null;
                });
        }
        return refreshPromise.current;
    };

    return (
        <UserContext.Provider
            value={{
                ...user,
                signIn,
                signOut,
                getAccessToken,
                _getAccessToken,
                refreshAccessToken,
                isAdmin
            }}
        >
            <CssBaseline />
            {user ? (
                <Layout>
                    <Routes />
                </Layout>
            ) : (
                <SignInRoutes />
            )}
        </UserContext.Provider>
    );
};

const reactPlugin = new ReactPlugin();

const AppContainer = () => {
    const theme = createMuiTheme();
    const browserHistory = createBrowserHistory();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            },
            disableFetchTracking: false
        }
    });
    if (process.env.REACT_APP_APPINSIGHTS_KEY) {
        appInsights.loadAppInsights();
    }

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router history={browserHistory}>
                    <App />
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default withAITracking(reactPlugin, AppContainer, "AppContainer");
