import { Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSearch from "../useSearch";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        margin: `0 ${theme.spacing()}px`,
        display: "flex",
        alignItems: "center",
        minWidth: 150
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    }
}));

const Search = ({ className, placeholder }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { q, getNewQueryString } = useSearch();
    const _q = q || "";
    const [searchText, setSearchText] = useState(_q);
    const input = useRef(null);

    const updateSearchText = text => {
        setSearchText(text);
        input.current.focus();
    };

    useEffect(() => {
        updateSearchText(_q);
    }, [_q]);

    return (
        <Paper
            component="form"
            onSubmit={ev => {
                ev.preventDefault();
                history.push({
                    ...location,
                    search: getNewQueryString(searchText)
                });
            }}
            className={clsx(classes.root, className)}
        >
            <InputBase
                inputRef={input}
                className={classes.input}
                placeholder={placeholder || "Search"}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
                autoFocus
            />
            {!!searchText.length && (
                <>
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => updateSearchText("")}
                    >
                        <Clear />
                    </IconButton>
                    <Divider
                        className={classes.divider}
                        orientation="vertical"
                    />
                </>
            )}
            <IconButton className={classes.iconButton} type="submit">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

export default Search;
