import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        textAlign: "center",
        margin: `${theme.spacing(4)}px 0`
    },
    icon: {
        width: "100%",
        height: "250px",
        [theme.breakpoints.down("xs")]: {
            height: "150px"
        },
        color: theme.palette.disabled
    },
    error: {
        color: theme.palette.error.light
    }
}));

const Info = ({ Icon, iconClassName, title, subtitle, error }) => {
    const classes = useStyles();
    const errorClass = error && classes.error;
    return (
        <div className={clsx(classes.root, errorClass)}>
            <Icon
                className={clsx(classes.icon, iconClassName, errorClass)}
                color="disabled"
            />
            <Typography variant="h5" component="h2" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body2">{subtitle}</Typography>
        </div>
    );
};

export default Info;
