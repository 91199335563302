import { PanTool } from "@material-ui/icons";
import React from "react";
import Heading from "../inputs/Heading";
import makeEditor from "../makeEditor";

const hazard = {
    name: "Hazard",
    type: "hazard",
    icon: <PanTool />,
    acceptsFocus: false,
    init: _ => ({ value: undefined }),
    editor: makeEditor("Hazard name"),
    render: ({ name }, index) => (
        <Heading key={index} name={name} hideDivider={index === 0} />
    ),
    fieldsToAdd: ["hazard", "risk", "multi"],
    specificToSection: "Job Specific Risk Assessment & Method Statements (RAMS)"
};

export default hazard;
