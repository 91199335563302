import {
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import useFeatures from "../useFeatures";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing()
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    },
    grid: {
        maxWidth: "1200px",
        justifyContent: "center"
    },
    gridItem: {
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    card: {
        display: "flex",
        padding: theme.spacing(2),
        margin: "auto",
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "330px"
        },
        justifyContent: "space-between"
    },
    icon: {
        fontSize: "96px",
        margin: "auto 0"
    },
    subtitle: {
        lineHeight: "normal !important",
        paddingTop: theme.spacing()
    }
}));

const Tab = ({ classes, to, target, title, Icon, subtitle }) => (
    <Grid className={classes.gridItem} item lg={4}>
        <Link
            to={{
                ...to,
                state: { text: title }
            }}
            className={classes.link}
            target={target}
        >
            <Card className={classes.card}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h2" variant="h6">
                            {title}
                        </Typography>
                        <Typography
                            className={classes.subtitle}
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            {subtitle}
                        </Typography>
                    </CardContent>
                </div>
                <Icon color="primary" className={classes.icon} />
            </Card>
        </Link>
    </Grid>
);

const Welcome = () => {
    const classes = useStyles();
    const features = useFeatures();
    return (
        <div className={classes.root}>
            <Grid container spacing={4} className={classes.grid}>
                {features
                    .filter(({ hidden }) => !hidden)
                    .map(({ url, search, name, target, description, Icon }) => (
                        <Tab
                            key={`${url}/${search}`}
                            title={name}
                            subtitle={description}
                            Icon={Icon}
                            classes={classes}
                            to={{
                                pathname: url,
                                search
                            }}
                            target={target}
                        />
                    ))}
            </Grid>
        </div>
    );
};

export default Welcome;
