import { Assignment, Visibility } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import {
    Action,
    BigError,
    FullScreenDialog,
    Grid,
    Loading
} from "../components";
import { initForm } from "../components/Form";
import { FormContents } from "../components/Form/Form";
import {
    makeColumn,
    modifiedByColumn,
    modifiedColumn,
    nameColumn
} from "../components/Grid/columns";
import sections from "../sections";
import useApi from "../useApi";
import FormDesigner from "./FormDesigner";

const Preview = ({ id, onClose }) => {
    const { single } = useCallback(useApi("forms"), []);
    const [form, setForm] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await single(id);
            if (response) {
                setForm(initForm(id, response));
            } else {
                setError(true);
            }
        })();
    }, [id, single]);

    return error ? (
        <BigError />
    ) : form ? (
        <FormContents
            item={form}
            onCancel={onClose}
            preview
            inline
            canChangeForms
        />
    ) : (
        <Loading />
    );
};

const newItem = {
    name: "",
    section: sections[0],
    fields: []
};

const Forms = () => {
    const [previewing, setPreviewing] = useState();
    const handleClose = () => setPreviewing(false);
    return (
        <>
            {previewing && (
                <FullScreenDialog
                    open={true}
                    onCancel={handleClose}
                    title={previewing.name}
                >
                    <Preview id={previewing.id} onClose={handleClose} />
                </FullScreenDialog>
            )}
            <Grid
                api="forms"
                columns={[
                    nameColumn,
                    makeColumn("Section", null, ["xs", "sm"]),
                    modifiedColumn,
                    modifiedByColumn
                ]}
                actions={[
                    {
                        getAction: ({ id, name }) => (
                            <Action
                                Icon={Visibility}
                                onClick={() => setPreviewing({ id, name })}
                                name="Preview"
                            />
                        )
                    }
                ]}
                newItem={newItem}
                empty={{
                    Icon: Assignment,
                    title: "Add some forms",
                    subtitle: "Add a form and it will show up here."
                }}
                addConfig={{
                    title: "Add Form"
                }}
                editConfig={{
                    title: "Edit Form"
                }}
                removeConfig={{
                    title: "Delete Form?"
                }}
                cloneConfig={{
                    clone: item => ({
                        ...item,
                        id: undefined,
                        name: `Copy of ${item.name}`,
                        fields: item?.fields.map(f => ({ ...f, id: undefined }))
                    })
                }}
                searchConfig={{
                    blank: "No Forms found"
                }}
                Editor={FormDesigner}
            />
        </>
    );
};

export default Forms;
