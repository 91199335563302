import {
    Button,
    List,
    ListSubheader,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import DocumentList from "./DocumentList";
import FormList from "./FormList";

const useStyles = makeStyles(theme => ({
    section: {
        margin: `${theme.spacing(2)}px 0`,
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        [theme.breakpoints.down("xs")]: {
            padding: `${theme.spacing(3)}px 0`,
            paddingRight: theme.spacing(0.5)
        }
    },
    heading: {
        padding: `0 ${theme.spacing(2)}px`
    },
    add: {
        margin: theme.spacing()
    }
}));

const has = array => array.length > 0;

const Section = ({
    name,
    forms,
    additionalForms,
    documents,
    onAddForm,
    onEditForm,
    onDownload,
    onDeleteForm,
    onExportPdf,
    canChangeForms
}) => {
    const classes = useStyles();

    const hasForms = canChangeForms
        ? has(forms)
        : has(forms.some(f => f.jobSheetForms.length > 0));
    const artifacts = has(documents) || has(additionalForms) || hasForms;

    return artifacts ? (
        <Paper key={name} className={classes.section}>
            <Typography className={classes.heading} variant="h6">
                {name}
            </Typography>
            <DocumentList documents={documents} onDownload={onDownload} />
            {forms.map(
                ({ id, name, jobSheetForms }) =>
                    (canChangeForms || jobSheetForms.length > 0) && (
                        <List key={`form-${id}`}>
                            <ListSubheader disableSticky={true}>
                                {name}
                            </ListSubheader>
                            <FormList
                                onEditForm={onEditForm}
                                onDeleteForm={onDeleteForm}
                                onExportPdf={onExportPdf}
                                forms={jobSheetForms}
                            />
                            {canChangeForms && (
                                <Button
                                    className={classes.add}
                                    onClick={() => onAddForm(id)}
                                    color="primary"
                                    startIcon={<Add />}
                                >
                                    Add {name}
                                </Button>
                            )}
                        </List>
                    )
            )}
            {additionalForms.length ? (
                <List key={`additional-forms`}>
                    <ListSubheader disableSticky={true}>
                        Additional Forms
                    </ListSubheader>
                    <FormList
                        onEditForm={onEditForm}
                        onDeleteForm={onDeleteForm}
                        onExportPdf={onExportPdf}
                        forms={additionalForms}
                    />
                </List>
            ) : null}
        </Paper>
    ) : null;
};

export default Section;
