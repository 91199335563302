import { Avatar, Hidden, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { getInitials, getName } from "../util";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: "0.7rem",
        marginRight: theme.spacing(),
        backgroundColor: theme.palette.primary.main
    },
    right: {
        justifyContent: "flex-end"
    }
}));

const User = ({ user, className, showName, right }) => {
    const classes = useStyles();
    const name = getName(user);
    return (
        <div className={clsx(classes.root, className, right && classes.right)}>
            <Avatar title={name} className={classes.avatar}>
                {getInitials(user)}
            </Avatar>
            {showName ? name : <Hidden smDown>{name}</Hidden>}
        </div>
    );
};

export default User;
