import React from "react";
import Detail from "./Detail";
import { MapLink } from "../../components";
import { Button } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { isEmpty } from "../../util";

const Address = ({ title, address }) => (
    <Detail
        title={title}
        text={
            isEmpty(address) && (
                <>
                    <div>
                        {address.number} {address.street1}
                    </div>
                    <div>{address.street2}</div>
                    <div>{address.city}</div>
                    <div>{address.county}</div>
                    <div>{address.postcode}</div>
                    {address.placeId && (
                        <MapLink address={address}>
                            <Button startIcon={<Room />} color="primary">
                                Find
                            </Button>
                        </MapLink>
                    )}
                </>
            )
        }
    />
);

export default Address;
