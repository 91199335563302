import React from "react";
import { Hidden, Button, IconButton } from "@material-ui/core";

const Action = ({ Icon, name, onClick }) => (
    <>
        <Hidden smDown>
            <Button startIcon={<Icon />} onClick={onClick} color="primary">
                {name}
            </Button>
        </Hidden>
        <Hidden mdUp>
            <IconButton title={name} onClick={onClick} color="primary">
                <Icon />
            </IconButton>
        </Hidden>
    </>
);

export default Action;
