import { Maximize } from "@material-ui/icons";
import React from "react";
import Heading from "../inputs/Heading";
import makeEditor from "../makeEditor";

const heading = {
    name: "Heading",
    type: "heading",
    icon: <Maximize />,
    acceptsFocus: false,
    init: _ => ({ value: undefined }),
    editor: makeEditor("Heading text"),
    render: ({ name }, index) => (
        <Heading key={index} name={name} hideDivider={index === 0} />
    )
};

export default heading;
