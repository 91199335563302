import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const Loading = () => (
    <>
        <Skeleton height={50} variant="text" />
        <Skeleton height={500} variant="rect" />
    </>
);

export default Loading;
