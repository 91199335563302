import { Divider, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing()
    },
    withDivider: {
        marginTop: theme.spacing(3)
    },
    text: {
        paddingTop: theme.spacing(3)
    }
}));

const Heading = ({ name, hideDivider = false }) => {
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.root, !hideDivider && classes.withDivider)}
        >
            {!hideDivider && <Divider />}
            <Typography
                className={clsx(!hideDivider && classes.text)}
                variant="h6"
            >
                {name}
            </Typography>
        </div>
    );
};

export default Heading;
