import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

const ConfirmDialog = ({ title, confirm, onConfirm, onCancel, children }) => {
    const [confirming, setConfirming] = useState(false);

    const handleConfirm = async () => {
        setConfirming(true);
        try {
            await onConfirm();
        } catch (e) {
            setConfirming(false);
            throw e;
        }
    };

    const handleCancel = () => {
        if (!confirming) {
            onCancel();
        }
    };

    return (
        <Dialog
            open={true}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={confirming}
                    onClick={handleCancel}
                    color="primary"
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    disabled={confirming}
                    onClick={handleConfirm}
                    color="primary"
                >
                    {confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
