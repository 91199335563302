import { Lock } from "@material-ui/icons";
import React from "react";
import InputField from "./InputField";

const PasswordField = ({
    name = "password",
    label = "Password",
    autoFocus
}) => (
    <InputField
        label={label}
        name={name}
        type="password"
        autoFocus={autoFocus}
        Icon={Lock}
    />
);

export default PasswordField;
