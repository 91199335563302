import { FormControl, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(theme => ({
    marginTop: {
        marginTop: theme.spacing(2)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    }
}));

const Field = ({ label, name, onChange, children, index }) => {
    const classes = useStyles();
    return (
        <FormControl
            onChange={onChange}
            className={clsx(
                classes.marginBottom,
                index !== 0 && classes.marginTop
            )}
            fullWidth
        >
            <Typography
                component="label"
                htmlFor={name}
                variant="body1"
                gutterBottom
            >
                {label}
            </Typography>
            {children}
        </FormControl>
    );
};

export default Field;
