import {
    Button,
    Grid,
    makeStyles,
    Paper,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { Done, DoneAll, PictureAsPdf } from "@material-ui/icons";
import React from "react";
import { DisplayDate, User } from "../../components";
import useUser from "../../useUser";
import { arrayFrom } from "../../util";
import Address from "./Address";
import Detail from "./Detail";
import UserList from "./UserList";

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        [theme.breakpoints.down("xs")]: {
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`
        }
    },
    rightSm: {
        [theme.breakpoints.up("sm")]: {
            textAlign: "right"
        }
    },
    rightMd: {
        [theme.breakpoints.up("sm")]: {
            textAlign: "right"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "inherit"
        }
    },
    export: {
        textAlign: "right",
        marginBottom: theme.spacing(2)
    }
}));

const GridItem = ({ className, children }) => (
    <Grid className={className} item xs={12} sm={6} md={3}>
        {children}
    </Grid>
);

const Summary = ({
    customer,
    siteAddress,
    contactName,
    contactPhoneNumber,
    nearestHospital,
    depot,
    teamLeader,
    teamMembers,
    startDate,
    finishDate,
    started,
    startedBy,
    finished,
    finishedBy,
    onFinishJob,
    onExportSummary,
    signedOff,
    signedOffBy,
    onSignOffJob,
    scope,
    workPack
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const { isAdmin } = useUser();

    return (
        <Paper className={classes.header}>
            <Grid spacing={3} container>
                <GridItem>
                    <Detail title="Customer" text={customer.name} />
                    <Address title="Site address" address={siteAddress} />
                    <Detail
                        title="Contact"
                        text={
                            (contactName || contactPhoneNumber) && (
                                <>
                                    <div>{contactName}</div>
                                    <div>{contactPhoneNumber}</div>
                                </>
                            )
                        }
                    />
                </GridItem>
                <GridItem className={classes.rightMd}>
                    <Address
                        title="Nearest hospital"
                        address={nearestHospital}
                    />
                </GridItem>
                <GridItem>
                    <Detail title="Depot" text={depot.name} />
                    <UserList
                        title="Team Leader"
                        users={arrayFrom(teamLeader)}
                    />
                    <UserList title="Team Members" users={teamMembers} />
                </GridItem>
                <GridItem className={classes.rightSm}>
                    <Detail
                        title="Expected start"
                        text={<DisplayDate date={startDate} hideTime />}
                    />
                    <Detail
                        title="Started"
                        text={
                            <>
                                <DisplayDate date={started} showTime />
                                <User user={startedBy} showName right={smUp} />
                            </>
                        }
                    />
                    <Detail
                        title="Expected finish"
                        text={
                            finishDate && (
                                <DisplayDate date={finishDate} hideTime />
                            )
                        }
                    />
                    {finished ? (
                        <Detail
                            title="Finished"
                            text={
                                <>
                                    <DisplayDate date={finished} showTime />
                                    <User
                                        user={finishedBy}
                                        showName
                                        right={smUp}
                                    />
                                </>
                            }
                        />
                    ) : (
                        <Detail
                            text={
                                <Button
                                    onClick={onFinishJob}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<Done />}
                                >
                                    Finish job
                                </Button>
                            }
                        />
                    )}
                    {signedOff ? (
                        <Detail
                            title="Signed off"
                            text={
                                <>
                                    <DisplayDate date={signedOff} showTime />
                                    <User
                                        user={signedOffBy}
                                        showName
                                        right={smUp}
                                    />
                                </>
                            }
                        />
                    ) : (
                        isAdmin &&
                        finished && (
                            <Detail
                                text={
                                    <Button
                                        onClick={onSignOffJob}
                                        color="primary"
                                        variant="contained"
                                        startIcon={<DoneAll />}
                                    >
                                        Sign off job
                                    </Button>
                                }
                            />
                        )
                    )}
                </GridItem>
            </Grid>
            <Detail title="Work Pack" text={workPack.name} />
            <Detail title="Scope" text={scope} />
            {isAdmin && (
                <div className={classes.export}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PictureAsPdf />}
                        onClick={onExportSummary}
                    >
                        Export to PDF
                    </Button>
                </div>
            )}
        </Paper>
    );
};

export default Summary;
