import {
    Button,
    Hidden,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { Delete, Edit, FileCopy } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, { useCallback, useEffect, useState } from "react";
import {
    AddButton,
    ConfirmDialog,
    DataView,
    ErrorMessage,
    FullScreenDialog
} from "../../components";
import useApi from "../../useApi";
import useSearch from "../../useSearch";
import useUser from "../../useUser";
import { nullify } from "../../util";
import Overlay from "../Overlay";
import defaultColumns from "./columns";

const useStyles = makeStyles(theme => ({
    actions: {
        padding: 0,
        paddingRight: theme.spacing(),
        whiteSpace: "nowrap"
    },
    error: {
        marginBottom: theme.spacing(2)
    },
    header: {
        whiteSpace: "nowrap"
    },
    overflow: {
        overflowWrap: "anywhere"
    },
    more: {
        width: "100%"
    }
}));

const Data = ({
    data,
    onDelete,
    onClone,
    canDelete,
    onEdit,
    columns,
    actions,
    continuationToken,
    onLoadMore,
    loadingMore
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down("xs"));

    // Hide the header on xs screens when there's only one visible
    const includeHeader = !xs || columns.filter(c => !c.hideOn).length > 1;
    return (
        <TableContainer component={Paper}>
            <Table>
                {includeHeader && (
                    <TableHead>
                        <TableRow>
                            {columns.map(({ name, hideOn }) => (
                                <Hidden key={name} only={hideOn}>
                                    <TableCell className={classes.header}>
                                        {name}
                                    </TableCell>
                                </Hidden>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {data.map(row => (
                        <TableRow hover key={row.id}>
                            {columns.map(
                                ({ name, getValue, hideOn, getTooltip }) => (
                                    <Hidden key={`data-${name}`} only={hideOn}>
                                        <TableCell
                                            title={
                                                getTooltip && getTooltip(row)
                                            }
                                            className={classes.overflow}
                                        >
                                            {getValue(row)}
                                        </TableCell>
                                    </Hidden>
                                )
                            )}
                            <TableCell
                                className={classes.actions}
                                align="right"
                            >
                                {actions.map(({ getAction }, i) => (
                                    <span key={`additional-action-${i}`}>
                                        {getAction(row)}
                                    </span>
                                ))}
                                {onEdit && (
                                    <IconButton
                                        title="Edit"
                                        onClick={onEdit(row)}
                                    >
                                        <Edit />
                                    </IconButton>
                                )}
                                {onClone && (
                                    <IconButton
                                        title="Clone"
                                        onClick={onClone(row)}
                                    >
                                        <FileCopy />
                                    </IconButton>
                                )}
                                {onDelete && (
                                    <IconButton
                                        title="Delete"
                                        onClick={onDelete(row)}
                                        disabled={canDelete && !canDelete(row)}
                                    >
                                        <Delete />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {continuationToken && (
                        <TableRow>
                            <TableCell colSpan={columns.length + 1}>
                                <Button
                                    disabled={loadingMore}
                                    onClick={onLoadMore}
                                    className={classes.more}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Load more results
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const Grid = ({
    api,
    columns = defaultColumns,
    actions = [],
    newItem,
    addConfig,
    editConfig,
    removeConfig,
    searchConfig,
    cloneConfig,
    empty,
    Editor,
    fullEditor,
    getName = item => item.name
}) => {
    const classes = useStyles();
    const { q, getQueryString } = useSearch();
    const { isAdmin } = useUser();
    const [editing, _setEditing] = useState(false);
    const setEditing = i => {
        setSavingError(false);
        _setEditing(i);
    };
    const {
        data,
        add,
        update,
        response,
        remove,
        single,
        loadError,
        _add,
        reload,
        continuationToken
    } = useApi(api, true, false);
    const [savingError, setSavingError] = useState(false);
    const [deleting, setDeleting] = useState();
    const [message, setMessage] = useState();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const handleSave = async item => {
        setSaving(true);
        setSavingError(false);
        if (item.id) {
            await update(item);
        } else {
            await add(item);
        }
        if (response.ok) {
            setEditing(null);
        } else {
            setSavingError(response.status);
        }
        setSaving(false);
    };

    const handleDelete = d => () => {
        setDeleting(d);
    };

    const handleCancelDelete = () => {
        setDeleting(null);
    };

    const handleConfirmDelete = async () => {
        await remove(deleting.id);
        setDeleting(null);
        if (!response.ok) {
            setMessage(
                `An error has occurred while trying to delete '${getName(
                    deleting
                )}'`
            );
        }
    };

    const noResults = q
        ? {
              Icon: empty.Icon,
              title: searchConfig?.blank || "No results",
              subtitle: (
                  <span>
                      Your search for <strong>{q}</strong> did not match. Try
                      searching for different keywords.
                  </span>
              )
          }
        : empty;

    const handleCancelEdit = () => {
        setEditing(null);
    };

    const handleCreateNew = () => {
        setEditing({ ...newItem });
    };

    const _reload = useCallback(reload, []);

    useEffect(() => {
        _reload(getQueryString());
    }, [_reload, getQueryString, q]);

    const handleLoadMore = async () => {
        setLoadingMore(true);
        await reload(getQueryString(), true);
        setLoadingMore(false);
    };

    const handleEdit = editing => async () => {
        setEditing(null);
        setLoading(true);
        const edit = await single(editing.id);
        if (response.ok) {
            setEditing(edit);
        } else {
            setMessage(
                `An error has occurred while trying to edit '${getName(
                    editing
                )}'`
            );
        }
        setLoading(false);
    };

    const handleClone = item => async () => {
        const toClone = await single(item.id);
        if (response.ok) {
            const clone = cloneConfig.clone(toClone);
            setEditing(clone);
        } else {
            setMessage(
                `An error has occurred while trying to clone '${getName(item)}'`
            );
        }
        setLoading(false);
    };

    return (
        <>
            <ErrorMessage message={message} onClose={nullify(setMessage)} />
            <DataView
                component={({ data }) => (
                    <Data
                        data={data}
                        columns={columns}
                        actions={actions}
                        onDelete={removeConfig && isAdmin && handleDelete}
                        onEdit={editConfig && isAdmin && handleEdit}
                        onClone={cloneConfig && isAdmin && handleClone}
                        canDelete={removeConfig && removeConfig.canDelete}
                        continuationToken={continuationToken}
                        onLoadMore={handleLoadMore}
                        loadingMore={loadingMore}
                    />
                )}
                data={data}
                empty={noResults}
                error={loadError}
            />
            {isAdmin && <AddButton onClick={handleCreateNew} />}
            {deleting && (
                <ConfirmDialog
                    title={removeConfig.title}
                    confirm="Delete"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                >
                    Delete <strong>{getName(deleting)}</strong>?
                </ConfirmDialog>
            )}
            <FullScreenDialog
                open={!!editing}
                fill={fullEditor}
                onCancel={handleCancelEdit}
                title={
                    editing && editing.id ? editConfig.title : addConfig.title
                }
            >
                {savingError && (
                    <Alert className={classes.error} severity="error">
                        An error has occurred while trying to save.
                    </Alert>
                )}
                {!!editing && (
                    <Editor
                        item={editing}
                        onSave={handleSave}
                        savingError={savingError}
                        onCancel={handleCancelEdit}
                        onAdd={_add}
                    />
                )}
                <Overlay open={saving} />
            </FullScreenDialog>
            <Overlay open={loading} />
        </>
    );
};

export default Grid;
