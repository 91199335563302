import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import * as Yup from "yup";
import useApi from "../../useApi";
import CredentialsForm from "./CredentialsForm";
import EmailField from "./EmailField";

const validationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required")
});

const initialValues = {
    email: ""
};

const ForgotPassword = () => {
    const [error, setError] = useState();
    const { response, post } = useApi("auth/forgotpassword");
    const [complete, setComplete] = useState(false);

    const handleSignIn = async ({ email }) => {
        setError();
        await post({ email });
        if (response.ok) {
            setComplete(true);
        } else {
            setError("Unable to reset your password. Try again later.");
        }
    };

    return (
        <CredentialsForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignIn}
            error={error}
            buttonText={!complete ? "Send password reset" : null}
            link={{
                to: "/",
                label: "Sign in"
            }}
        >
            <Typography variant="h6" gutterBottom>
                Password reset
            </Typography>
            {complete ? (
                <Typography variant="body1" gutterBottom>
                    Check your email for your password reset link.
                </Typography>
            ) : (
                <>
                    <Typography variant="body1" gutterBottom>
                        Enter your email and we will send you a link to reset
                        your password.
                    </Typography>
                    <EmailField autoFocus />
                </>
            )}
        </CredentialsForm>
    );
};

export default ForgotPassword;
