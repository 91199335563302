import React, { useState } from "react";
import * as Yup from "yup";
import useApi from "../../useApi";
import useUser from "../../useUser";
import CredentialsForm from "./CredentialsForm";
import EmailField from "./EmailField";
import PasswordField from "./PasswordField";

const validationSchema = Yup.object({
    email: Yup.string().required("Email address is required"),
    password: Yup.string().required("Password is required")
});

const initialValues = {
    email: "",
    password: ""
};

const SignIn = () => {
    const [error, setError] = useState();
    const { response, post } = useApi("auth");
    const { signIn } = useUser();

    const handleSignIn = async ({ email, password }) => {
        setError();
        const token = await post({ email, password });
        if (response.ok) {
            signIn({ email, ...token });
        } else {
            if (response.status === 401) {
                setError("Username or password is incorrect.");
            } else {
                setError("Unable to sign in. Try again later.");
            }
        }
    };

    return (
        <CredentialsForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignIn}
            error={error}
            buttonText="Sign in"
            link={{
                to: "/forgot",
                label: "Forgot password?"
            }}
        >
            <EmailField autoFocus />
            <PasswordField />
        </CredentialsForm>
    );
};

export default SignIn;
