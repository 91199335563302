import { Event } from "@material-ui/icons";
import React from "react";
import DatePicker from "../inputs/DatePicker";
import makeEditor from "../makeEditor";
import makeInput from "../makeInput";

const date = {
    name: "Date",
    type: "date",
    icon: <Event />,
    acceptsFocus: true,
    editor: makeEditor("Date label"),
    render: makeInput(DatePicker)
};

export default date;
