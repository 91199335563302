import React from "react";
import AddressPicker from "./AddressPicker";

const FormikAddressPicker = ({ formik, name }) => (
    <AddressPicker
        address={formik.values[name]}
        onChange={value => {
            formik.handleChange({
                target: {
                    name,
                    value
                }
            });
        }}
    />
);

export default FormikAddressPicker;
