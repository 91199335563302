import { Fab, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(theme => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

const AddButton = ({ onClick }) => {
    const classes = useStyles();
    return (
        <Fab color="secondary" className={classes.fab} onClick={onClick}>
            <Add />
        </Fab>
    );
};

export default AddButton;
