import {
    Autorenew,
    Done,
    InsertDriveFile,
    PowerSettingsNew,
    Slideshow,
    Star
} from "@material-ui/icons";
import React, { useState } from "react";
import {
    Action,
    DisplayDate,
    ErrorMessage,
    FullScreenDialog,
    Grid
} from "../components";
import { makeColumn } from "../components/Grid/columns";
import { toDateString } from "../dates";
import useApi from "../useApi";
import useUser from "../useUser";
import { nullify } from "../util";
import JobSheetContents from "./JobSheetContents";
import JobSheetEditor from "./JobSheetEditor";

const newItem = {
    jobNumber: "",
    startDate: toDateString(new Date()),
    depot: null,
    workPack: null,
    customer: null
};

const jobNumberColumn = makeColumn("Job Number", "jobNumber", null);

const color = "primary";

const notStartedState = {
    text: "Not yet started",
    Icon: PowerSettingsNew,
    action: "Start job",
    isNew: true
};

const inProgressState = {
    text: "In progress",
    Icon: Autorenew,
    action: "Continue job"
};

const finishedState = {
    text: "Finished",
    Icon: Done,
    action: "View job"
};

const signedOffState = {
    text: "Signed off",
    Icon: Star,
    action: "View job"
};

const getState = ({ started, finished, signedOff }) => {
    // if statements more readable than nested ternaries here:
    if (signedOff) return signedOffState;
    if (finished) return finishedState;
    if (started) return inProgressState;
    return notStartedState;
};

const iconColumn = {
    name: "",
    hideOn: ["xs"],
    getTooltip: job => getState(job).text,
    getValue: job => {
        const I = getState(job).Icon;
        return <I color={color} />;
    }
};

const JobSheets = () => {
    const [current, setCurrent] = useState();
    const { isAdmin } = useUser();
    const { post, response } = useApi("jobsheets");
    const [message, setMessage] = useState();
    const handleClose = () => setCurrent();

    const startJob = async ({ id, jobNumber }) => {
        await post(`/${id}/start`, { id });
        if (response.ok) {
            setCurrent({ id, jobNumber });
        } else {
            setMessage(`Unable to start '${jobNumber}'`);
        }
    };

    const actions = [
        {
            getAction: job => {
                const state = getState(job);
                const { id, jobNumber } = job;
                return (
                    <Action
                        Icon={Slideshow}
                        onClick={
                            state.isNew
                                ? () => startJob({ id, jobNumber })
                                : () => setCurrent({ id, jobNumber })
                        }
                        name={state.action}
                    />
                );
            }
        }
    ];

    return current ? (
        <FullScreenDialog
            open={true}
            onCancel={handleClose}
            title={current.jobNumber}
            grey
        >
            <JobSheetContents onClose={handleClose} id={current.id} />
        </FullScreenDialog>
    ) : (
        <>
            <ErrorMessage message={message} onClose={nullify(setMessage)} />
            <Grid
                api="jobsheets"
                newItem={newItem}
                getName={jobNumberColumn.getValue}
                columns={[
                    iconColumn,
                    jobNumberColumn,
                    {
                        name: "Start Date",
                        hideOn: "xs",
                        getValue: ({ startDate }) => (
                            <DisplayDate date={startDate} hideTime />
                        )
                    },
                    makeColumn("Depot", "depotName", ["xs", "sm"]),
                    makeColumn("Customer", "customerName", ["xs", "sm"])
                ]}
                actions={actions}
                empty={{
                    Icon: InsertDriveFile,
                    title: isAdmin
                        ? "Add some job sheets"
                        : "No job sheets available",
                    subtitle: isAdmin
                        ? "Create a job sheet and it will show up here."
                        : "When you have a job sheet assigned to you it will show up here."
                }}
                addConfig={{
                    title: "Add Job Sheet"
                }}
                editConfig={{
                    title: "Edit Job Sheet"
                }}
                removeConfig={{
                    title: "Delete Job Sheet?"
                }}
                searchConfig={{
                    blank: "No Job Sheets found"
                }}
                Editor={JobSheetEditor}
            />
        </>
    );
};

export default JobSheets;
