import DateFnsUtils from "@date-io/date-fns";

const toDateString = date =>
    date
        ? [
              String(date.getFullYear()),
              String(101 + date.getMonth()).substring(1),
              String(100 + date.getDate()).substring(1)
          ].join("-")
        : null;

const fromDateString = s =>
    s ? new DateFnsUtils().parse(s.substring(0, 10), "yyyy-MM-dd") : null;

export { toDateString, fromDateString };
