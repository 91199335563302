import { makeStyles } from "@material-ui/core";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { Dashboard } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef, useState } from "react";
import useUser from "../useUser";

const maxFileSize = 10 * 1024 * 1024; // ~10MB

const useStyles = makeStyles(_ => ({
    "@global": {
        ".uppy-full-screen, .uppy-full-screen > div, .uppy-Dashboard,.uppy-Dashboard-inner": {
            height: "100% !important"
        },
        ".uppy-retrying .uppy-Dashboard-progressindicators": {
            display: "none"
        }
    }
}));

const combine = (elements, separator) =>
    elements.map(str => str.toString().padStart(2, "0")).join(separator);

const getDateForFilename = () => {
    const dt = new Date();
    const date = combine([dt.getFullYear(), dt.getMonth(), dt.getDate()], "-");
    const time = combine(
        [dt.getHours(), dt.getMinutes(), dt.getSeconds()],
        ":"
    );
    return `${date} ${time}`;
};

const rename = [
    /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/, // guid default for IOS
    /^\d{20,}$/ // long number for android
];

const getFilename = ({ name, type }) => {
    // if the filename given is a default looking one (e.g. a guid),
    // then try to rename it a bit more sensibly
    const dot = name.lastIndexOf(".");
    const filename = name.substr(0, dot);
    const extension = name.substr(dot + 1);
    return rename.some(r => r.test(filename))
        ? `${type.split("/")[0]} ${getDateForFilename()}.${extension}`
        : name;
};

const DocumentEditor = ({
    onAdd,
    onCancel,
    endpoint = "/api/library",
    meta
}) => {
    useStyles();
    const { _getAccessToken: getAccessToken, refreshAccessToken } = useUser();
    const [retrying, setRetrying] = useState(false);

    const uppy = useRef(
        Uppy({
            meta,
            limit: 10, // 10 concurrent uploads. Others will wait.
            restrictions: { maxFileSize },
            onBeforeFileAdded: file => {
                if (file.data.size === 0) {
                    uppy.current.info("File cannot be empty");
                    return false;
                }
                return {
                    ...file,
                    name: getFilename(file)
                };
            }
        })
            .use(XHRUpload, {
                endpoint,
                fieldName: "file",
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`
                }
            })
            .on("upload-success", (_, response) => {
                onAdd(response.body);
            })
            .on("complete", result => {
                // Close the dialog if nothing failed.
                if (!result.failed.length) {
                    onCancel();
                }
            })
            .on("upload", _ => {
                setRetrying(false);
            })
            .on("upload-error", async (file, _, response) => {
                if (response.status === 401) {
                    uppy.current.hideInfo();
                    setRetrying(true);
                    const { accessToken } = await refreshAccessToken();
                    if (accessToken) {
                        uppy.current.getPlugin("XHRUpload").setOptions({
                            headers: {
                                Authorization: `Bearer ${getAccessToken()}`
                            }
                        });
                        uppy.current.retryUpload(file.id);
                    }
                } else {
                    setRetrying(false);
                }
            })
    );

    useEffect(() => {
        const current = uppy.current;
        return () => {
            current.close();
        };
    }, []);
    return (
        <div className={`uppy-full-screen ${retrying ? "uppy-retrying" : ""}`}>
            <Dashboard
                width="inherit"
                uppy={uppy.current}
                proudlyDisplayPoweredByUppy={false}
            ></Dashboard>
        </div>
    );
};

export default DocumentEditor;
