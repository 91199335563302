import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import React from "react";
import RiskScore from "../RiskScore";

const ControlMeasures = ({
    autoFocus,
    disabled,
    className,
    controlMeasures,
    onChange
}) => {
    return (
        <FormControl className={className} component="fieldset">
            <FormLabel component="legend" focused={false}>
                Control Measures
            </FormLabel>
            <FormGroup>
                {controlMeasures?.map(({ name, value }, i) => (
                    <FormControlLabel
                        key={i}
                        control={
                            <Checkbox
                                autoFocus={autoFocus && i === 0}
                                disabled={disabled}
                                name={name}
                                checked={value}
                                onChange={e =>
                                    onChange(
                                        { name, value: e.target.checked },
                                        i
                                    )
                                }
                            />
                        }
                        label={name}
                    />
                ))}
            </FormGroup>
            <FormHelperText>
                Select which control measures are in place
            </FormHelperText>
        </FormControl>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2)
    },
    controlMeasures: {
        width: "100%",
        margin: `${theme.spacing(2)}px 0`
    },
    grid: {
        padding: `0 ${theme.spacing(2)}px`
    }
}));

const Risk = ({
    autoFocus,
    index,
    label,
    onChange,
    disabled,
    controlMeasures,
    likelihood,
    severity
}) => {
    const classes = useStyles();
    const name = `fields[${index}].controlMeasures`;
    const handleChange = (changed, idx) => {
        const value = controlMeasures.map((cm, i) =>
            i === idx ? { ...cm, ...changed } : cm
        );
        onChange({
            target: { name, value }
        });
    };

    return (
        <div className={classes.root}>
            <Typography gutterBottom>{label}</Typography>
            <Grid className={classes.grid} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <ControlMeasures
                        autoFocus={autoFocus}
                        disabled={disabled}
                        onChange={handleChange}
                        className={classes.controlMeasures}
                        controlMeasures={controlMeasures}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RiskScore likelihood={likelihood} severity={severity} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Risk;
